"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusUserAccount
 * @author:     Richard B Winters
 * @copyright:  2020 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.1.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// EXPORTS
var FocusUserAccount_1 = require("./FocusUserAccount");
exports.default = FocusUserAccount_1.default;
exports.FocusUserAccount = FocusUserAccount_1.FocusUserAccount;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    AppAdvisories
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("../../redux/actions");
const Advisories_1 = require("../../components/Advisories");
const Advisory_1 = require("../../components/Advisory");
const mapStateToProps = (state) => ({
    advisories: state.advisories
});
const mapDispatchToProps = (dispatch) => ({
    submitAdvisory: (advisory) => dispatch(actions_1.submitAdvisory(advisory)),
    removeAdvisory: (advisory) => dispatch(actions_1.removeAdvisory(advisory))
});
/**
 * AppAdvisoriescontainer component
 *
 * @since 0.1.0
 */
class AppAdvisories extends React.PureComponent {
    /**
     * Constructor
     *
     * @param { AppAdvisoriesProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'AppAdvisories';
        this.state = {
            advisories: []
        };
        this.handleClose = this.handleClose.bind(this);
    }
    /**
     * Initialize all kux controls immediately after component mounts
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    static getDerivedStateFromProps(nextProps, previousState) {
        if (previousState.advisories !== nextProps.advisories) {
            return nextProps.advisories;
        }
    }
    /*
    componentDidUpdate( prevProps: any )
    {
        if( prevProps.advisories !== ( this.props as any ).advisories )
        {
            this.setState( { advisories: ( this.props as any ).advisories } );
        }
    }
    */
    handleClose(event) {
        event.preventDefault();
        let removedAdvisory = this.props.removeAdvisory({ id: event.target.id });
    }
    getAdvisories() {
        const advisoryList = [], { advisories } = this.props.advisories;
        let uniqueKey = new Date().getTime();
        for (let advisory in advisories) {
            advisoryList.push(React.createElement(Advisory_1.Advisory, Object.assign({ key: uniqueKey++ }, advisories[advisory])));
        }
        let advisoryContainer = (React.createElement(Advisories_1.Advisories, { key: uniqueKey++, list: advisoryList }));
        //console.log( 'Compiled advisories: ' );
        //console.log( advisoryList );
        //this.setState( { advisories } );
        return advisoryContainer;
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @since 0.1.0
     */
    render() {
        // Fetch the advisories list from our mapped props:
        const { advisories } = this.props.advisories;
        // Create a device for generating a unique id:
        let ts = new Date().getTime();
        // Map each advisory:
        const advisoryList = advisories.map((advisory) => React.createElement(Advisory_1.Advisory, Object.assign({ key: ts++, closeHandler: this.handleClose }, advisory)));
        // Finally, return render-able components:
        return (React.createElement(Advisories_1.Advisories, { key: ts++, list: advisoryList }));
    }
}
exports.AppAdvisories = AppAdvisories;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
AppAdvisories.defaultProps = {};
// Here we wrap the AppAdvisories Container with a Connect container,
// creating the AppAdvisoriesContainer:
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(AppAdvisories);

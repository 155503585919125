"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Footer
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * Footer presentational component
 *
 * @param { FooterProps } props
 *
 * @since 0.1.0
 */
exports.Footer = (props) => {
    // Here we render the component:
    return (React.createElement("footer", { className: _1.styles.nodekitAppFooter },
        React.createElement("div", { className: _1.styles.nodekitAppFooterRow },
            React.createElement("section", { className: _1.styles.nodekitAppFooterSectionRight }, props.linkListRight),
            React.createElement("section", { className: _1.styles.nodekitAppFooterSectionLeft }, props.linkListLeft),
            React.createElement("section", { className: _1.styles.nodekitAppFooterSectionCenter },
                React.createElement("div", { className: _1.styles.nodekitAppFooterBrand + " icon-logo-badge-small" }))),
        React.createElement("div", { className: _1.styles.nodekitAppFooterSubFooter },
            React.createElement("span", { className: _1.styles.nodekitAppFooterCopyrightText },
                React.createElement("small", null,
                    "\u00A9 ",
                    ((props.copyrightStart) ? (props.copyrightStart + "-") : ""),
                    props.copyrightEnd + " " + props.copyrightHolder)),
            React.createElement("span", { className: _1.styles.nodekitAppFooterReservedText },
                React.createElement("small", null, "All Rights Reserved.")))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Footer.displayName = 'Footer';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

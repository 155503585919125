exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1bNlGAi9sWapa7eE69eY4g {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: 100%;\n  text-align: justify; }\n", ""]);

// exports
exports.locals = {
	"privacy-container": "_1bNlGAi9sWapa7eE69eY4g",
	"privacyContainer": "_1bNlGAi9sWapa7eE69eY4g"
};
"use strict";
/*-----------------------------------------------------------------------------
 * @package:    UserRegistration
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const styles = require("./styles.scss");
exports.styles = styles;
var UserRegistration_1 = require("./UserRegistration");
exports.UserRegistration = UserRegistration_1.UserRegistration;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    ECITMBrand
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * MModBrand presentational component
 *
 * @param { ECITMBrandProps } props
 *
 * @since 0.1.0
 */
exports.ECITMBrand = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.eciTmBrandContainer },
        React.createElement("div", { className: _1.styles.eciTmBrandImage })));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.ECITMBrand.displayName = 'ECITMBrand';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

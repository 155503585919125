exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Yxlz4X4Qi2OM67A9bKnVn, ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: auto; }\n  ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA {\n    margin-bottom: 10px;\n    padding: 10px;\n    min-height: 48px;\n    border: 1px solid #A02C2C;\n    border-radius: 3px; }\n    .advisory-item-container .advisory-item.on-success {\n      border-color: #A02C2C; }\n    .advisory-item-container .advisory-item.on-error {\n      border-color: #b84f4f; }\n      .advisory-item-container .advisory-item.on-error ._2yo0exXD3Vi4jC28Zw5oAL {\n        color: #a0522d; }\n    ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA ._2yo0exXD3Vi4jC28Zw5oAL {\n      margin-top: -8px;\n      margin-right: -8px;\n      width: auto;\n      display: inline-flex;\n      float: right;\n      color: #485868;\n      cursor: pointer; }\n    ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA .OP5ruAtTRIXbqlfTb_nk4 {\n      display: block;\n      /*float: left;*/\n      text-align: left;\n      font-size: .85em;\n      font-weight: 600;\n      /*:global( .error-message-major ) {\n                color: $theme-pale-error;\n            }*/ }\n    ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA ._3F5nquplLHMyr-ngOh-_UH {\n      display: block;\n      width: 100%;\n      font-size: .85em; }\n      ._1Yxlz4X4Qi2OM67A9bKnVn ._3wvO9wIFhyrcq03aQLfttA ._3F5nquplLHMyr-ngOh-_UH .error-message-minor {\n        color: #b84f4f; }\n", ""]);

// exports
exports.locals = {
	"advisory-item-container": "_1Yxlz4X4Qi2OM67A9bKnVn",
	"advisoryItemContainer": "_1Yxlz4X4Qi2OM67A9bKnVn",
	"advisory-item": "_3wvO9wIFhyrcq03aQLfttA",
	"advisoryItem": "_3wvO9wIFhyrcq03aQLfttA",
	"advisory-item_close-button": "_2yo0exXD3Vi4jC28Zw5oAL",
	"advisoryItemCloseButton": "_2yo0exXD3Vi4jC28Zw5oAL",
	"advisory-item_title": "OP5ruAtTRIXbqlfTb_nk4",
	"advisoryItemTitle": "OP5ruAtTRIXbqlfTb_nk4",
	"advisory-item_content": "_3F5nquplLHMyr-ngOh-_UH",
	"advisoryItemContent": "_3F5nquplLHMyr-ngOh-_UH"
};
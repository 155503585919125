"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusLogin
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const context_1 = require("../../context");
const actions_1 = require("../../redux/actions");
const helpers_1 = require("../../helpers");
const ECIBrand_1 = require("../../components/ECIBrand");
const Login_1 = require("../../components/Login");
const KMDTextField_1 = require("../../components/MaterialDesign/KMDTextField");
const KMDCheckbox_1 = require("../../components/MaterialDesign/KMDCheckbox");
const mapStateToProps = (state) => ({
    user: state.users.user,
    advisories: state.advisories
});
const mapDispatchToProps = (dispatch) => ({
    submitAdvisory: (advisory) => dispatch(actions_1.submitAdvisory(advisory)),
    signInUser: (credentials, history, sessionContext) => dispatch(actions_1.signInUser(credentials, history, sessionContext)),
    signOutUser: (history) => dispatch(actions_1.signOutUser(history))
});
/**
 * FocusLogincontainer component
 *
 * @since 0.1.0
 */
class FocusLogin extends React.Component {
    /**
     * Constructor
     *
     * @param { FocusLoginProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'FocusLogin';
        this.state = {
            user: {},
            username: "",
            password: "",
            rememberMe: false,
            formValid: false,
            validations: {
                username: {
                    valid: false,
                    exists: false,
                    required: false,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                email: {
                    valid: false,
                    exists: false,
                    required: false,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                password: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                rememberMe: {
                    valid: false,
                    exists: false,
                    required: false,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                }
            }
        };
        this.handleFormFieldInput = this.handleFormFieldInput.bind(this);
        this.handleUsernameInputChange = this.handleUsernameInputChange.bind(this);
        this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
        this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Method which runs once a omponent has mounted
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        // Call kux.controls() to instantiate all the UX Goodies
        kux.controls();
        //console.log( 'Called componentDidMount() from FocusLogin' );
        console.log(`componentDidMount() in FocusLogin container says context: `);
        console.log(this.context);
    }
    /**
     * Makes use of a validation helper for implementing form validation
     *
     * @param { string } field The field validation is run for
     * @param { string } value A field's input, converted to a string
     *
     * @returns { InputValidationType }
     *
     * @since 0.1.0
     */
    getValidation(field, value) {
        let options = {
            required: false,
            constrain: false,
            exclude: false,
            regex: /[~`!@#$%\^&*()_+=\-\[\]\\';,/{}|\\":<>\?]/g,
            errorMessage: ""
        }, validator = new helpers_1.FormValidation(), formField;
        switch (field) {
            case 'username':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.username.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'email':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.email.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'password':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.password.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'rememberMe':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.rememberMe.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
        }
        return formField;
    }
    /**
     * Handle change event for the username text input
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleUsernameInputChange(event) {
        //console.log( `InputChangeEvent: ${event.target.id}: ${event.target.value}` );
        // Set the username by the input provided:
        this.setState({ [event.target.id]: event.target.value });
    }
    /**
     * Handle change event for the password text input
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handlePasswordInputChange(event) {
        // Set the password by the input provided:
        this.setState({ [event.target.id]: event.target.value });
    }
    /**
     * Handle change event for the RememberMe check box
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleRememberMeChange(event) {
        console.log(`RememberMe Value: ${event.target.value}!`);
        this.setState({ [event.target.id]: ((event.target.value) ? true : false) });
    }
    /**
     * Handle change event for the username text input
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleFormFieldInput(event) {
        //console.log( `InputChangeEvent: ${event.target.id}: ${event.target.value}` );
        // Get the validation state for this input:
        let inputValidation = this.getValidation(event.target.id, event.target.value), { validations, formValid } = this.state;
        validations = Object.assign(Object.assign({}, validations), { [event.target.id]: inputValidation });
        formValid = ((validations.username.valid || validations.email.valid) && validations.password.valid);
        // Set the input value by the input provided:
        this.setState({ [event.target.id]: event.target.value });
        this.setState({ validations, formValid });
    }
    /**
     * Handle submit event for the form submit button
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleSubmit(event) {
        event.preventDefault();
        //console.log( `Submit pressed! Attempting to dispatch API request...` );
        let formData = new FormData();
        formData.append('email', 'devrikx@gmail.com');
        formData.append('password', '^Rkitek720$');
        formData.append('rememberMe', '1');
        const { email, password, rememberMe, formValid } = this.state;
        // Here we dispatch the registration action - errors will be handled through
        // the dispatch actions error handler, and if no errors occur then we will
        // forward the user to the verification component?
        if (formValid) {
            let loginResult = this.props.signInUser({ email: email, password: password, rememberMe: rememberMe }, this.props.history, this.context);
        }
        else {
            let ts = new Date();
            this.props.submitAdvisory({
                id: `FORM_ERROR`,
                title: `FORM ERROR ${ts.getTime()}`,
                content: `Please review the form and make corrections to form field inputs where necessary.`,
                isError: true
            });
        }
    }
    /**
     * Returns the brand image component
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getImageBrand() {
        return (React.createElement(ECIBrand_1.ECIBrand, null));
    }
    /**
     * Returns the email text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getEmailInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "email", name: "email", label: "Email", input: "", extras: { required: true }, leadingIcon: true, icon: "account_box", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the password text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getPasswordInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "password", name: "password", label: "Password", input: "", extras: { required: true }, password: true, leadingIcon: true, icon: "vpn_key", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the remember me chcekbox input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getRememberMeInput() {
        return (React.createElement(KMDCheckbox_1.KMDCheckbox, { id: "rememberMe", name: "rememberMe", label: "Remember me", touchTarget: true, handleCheck: this.handlePasswordInputChange }));
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        return (React.createElement(Login_1.Login, { brand: this.getImageBrand(), email: this.getEmailInput(), password: this.getPasswordInput(), rememberMe: this.getRememberMeInput(), handleSubmit: this.handleSubmit }));
    }
}
exports.FocusLogin = FocusLogin;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
FocusLogin.defaultProps = {};
/**
 * @var { SessionContext } contextType The login component is a subscriber of the SessionContext
 *
 * @since 0.1.0
 */
FocusLogin.contextType = context_1.SessionContext;
// Here we wrap the FocusLogin Container with a Connect container,
// creating the FocusLoginContainer:
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(react_router_dom_1.withRouter(FocusLogin));

"use strict";
/*------------------------------------------------------------------------------
 * @package:   UnderConstruction
 * @author:    Richard B Winters
 * @copyright: 2018 Massively Modified, Inc..
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * MessageBoardList presentational component
 *
 * @param { UnderConstructionProps } props
 *
 * @since 0.1.0
 */
exports.UnderConstruction = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.underConstructionContainer },
        React.createElement("div", { className: "row " + _1.styles.focusHeader },
            React.createElement("div", { className: "column+12 column+8 column+4" },
                React.createElement("img", { src: "assets/images/eci-logo.png", alt: "Edison Computers Logo" }),
                React.createElement("h1", null,
                    React.createElement("span", { className: _1.styles.titleCapture }, props.title),
                    " is Under Construction"))),
        React.createElement("div", { className: _1.styles.focusContent },
            React.createElement("div", { className: _1.styles.ucWrap + " " + _1.styles.ucMessage },
                React.createElement("h1", null, "Whoa!?"),
                React.createElement("small", null,
                    "We apologize for any inconvenience. Check back often for updates, or ",
                    React.createElement("a", { href: "mailto:edisoncomputers@gmail.com" }, "Email Support"),
                    " with any questions or concerns.")))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.UnderConstruction.displayName = "UnderConstruction";
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

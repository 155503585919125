"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusUserAccount
 * @author:     Richard B Winters
 * @copyright:  2020 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.1.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_redux_1 = require("react-redux");
const mapStateToProps = (state) => {
    return {};
};
/**
 * FocusUserAccountcontainer component
 *
 * @since 0.1.0
 */
class FocusUserAccount extends React.Component {
    /**
     * Constructor
     *
     * @param props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'FocusUserAccount';
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @since 0.1.0
     */
    render() {
        return ("");
    }
}
exports.FocusUserAccount = FocusUserAccount;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
FocusUserAccount.defaultProps = {};
// Here we wrap the FocusUserAccount Container with a Connect container,
// creating the FocusUserAccountContainer:
exports.default = react_redux_1.connect(mapStateToProps)(FocusUserAccount);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* This brings the pop-up menu a little higher up\n   so that it overlaps the topAppBar properly and\n   gives the correct effect! */\n.mdc-top-app-bar .mdc-menu--open {\n  top: 42px !important; }\n\n._3FVuOqGVavEEBTMuPDB1VN {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: 100%; }\n", ""]);

// exports
exports.locals = {
	"changeMe": "_3FVuOqGVavEEBTMuPDB1VN"
};
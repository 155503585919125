"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const cross_fetch_1 = require("cross-fetch");
const action_types_1 = require("../constants/action-types");
const actions_1 = require("../actions");
// DEFINES
/* Dispatch Actions for verifying a user via API: */
exports.verifyUserBegin = () => ({ type: action_types_1.VERIFY_USER_BEGIN });
exports.verifyUserSuccess = (verification) => ({ type: action_types_1.VERIFY_USER_SUCCESS, payload: { verification } });
exports.verifyUserError = (error) => ({ type: action_types_1.VERIFY_USER_ERROR, payload: { error } });
/**
 * Method to verify a user through our API via `fetch`
 *
 * @param { string } verificationInfo The verification token emailed to the newly registered user,
 *                         or to the user who recently updated their email address.
 *
 * @return { Promise<any> }
 *
 * @since 0.1.0
 */
function verifyUser(verificationInfo, history) {
    // Return a function (making this action a closure):
    return (dispatch) => {
        // Our API requests always happen in 3 parts. Start by signifying that
        // we're processing an API request (loading status flag thrown when we
        // dispatch our begin action):
        dispatch(exports.verifyUserBegin());
        // Return a fetch request to our endpoint using the passed form data:
        return cross_fetch_1.default("/api/user/verify", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "apiVersion": "1.0",
                "method": "user.verify",
                "params": Object.assign({}, verificationInfo)
            })
        }) // Follow up with a check for errors:
            .then(response => handleErrors(response, dispatch))
            //.then( response => response.json() )    // Parse our JSON object [again] after error handling:
            .then(
        // Finally let us leave the user in a position to log-in to their account:
        (verification) => {
            // Dispatch our success action, and throw the verification data into our store:
            dispatch(exports.verifyUserSuccess(verification.data.items));
            // Then redirect the user to the login route:
            history.push('/login');
            // Finally, return the fetch promise object:
            return verification;
        }) // Catch any errors and handle them appropriately (dispatch our error action):
            .catch((error) => dispatch(exports.verifyUserError(error)));
    };
}
exports.verifyUser = verifyUser;
/**
 * A method to handle HTTP Errors
 *
 * @param { * } response The fetch response
 *
 * @return { any } Returns the response if no errors, else it throws the error
 *
 * @since 0.1.0
 */
function handleErrors(response, dispatch) {
    // Check for a particular type of error?
    //if( !response.ok )
    //{
    //    throw Error( response.statusText );
    //}
    // Define a method to parse the response object (it'll be JSON):
    let parsedResponse = (response) => response.json();
    // Then return a series of promises that run off the defined method's return:
    return parsedResponse(response)
        .then(// Handle the error by submitting an advisory to alert the user:
    (verification) => {
        if (verification && verification.error && verification.error.code && verification.error.code < 500) {
            let ts = new Date();
            dispatch(actions_1.submitAdvisory({
                id: `${(verification.error.errors[0].reason).replace('_', ' ').replace(/[\[\]]+/g, '')}`,
                title: `${(verification.error.errors[0].reason).replace('_', ' ').replace(/[\[\]]+/g, '')} ${ts.getTime()}`,
                content: `${verification.error.message}: '${verification.error.errors[0].message}'.`,
                isError: true
            }));
        }
        return verification;
    })
        .then(
    // Decide how to handle errors:
    (verification) => {
        if (verification && verification.error && verification.error.code && verification.error.code > 499) {
            throw Error(verification.error);
        }
        // Otherwise let's return the response to the action method
        // to be processed further (bad requests yield advisories):
        return verification;
    });
}

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1AajC9yIM_PGw86Cqt78t- {\n  position: relative;\n  margin: 4px;\n  padding: 0;\n  display: block; }\n  ._1AajC9yIM_PGw86Cqt78t- select {\n    -webkit-appearance: none; }\n  ._1AajC9yIM_PGw86Cqt78t- .mdc-select__anchor {\n    width: 100%;\n    background-color: #ffffff; }\n  ._1AajC9yIM_PGw86Cqt78t- .mdc-select__menu,\n  ._1AajC9yIM_PGw86Cqt78t- .mdc-menu-surface {\n    width: 100%;\n    background-color: #ffffff;\n    text-align: left; }\n    ._1AajC9yIM_PGw86Cqt78t- .mdc-select__menu.mdc-list,\n    ._1AajC9yIM_PGw86Cqt78t- .mdc-menu-surface.mdc-list {\n      width: 100%; }\n", ""]);

// exports
exports.locals = {
	"mdc-select_container": "_1AajC9yIM_PGw86Cqt78t-",
	"mdcSelectContainer": "_1AajC9yIM_PGw86Cqt78t-"
};
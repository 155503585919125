exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3azRoMfPxHujgRO09wt3w6 {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: auto; }\n", ""]);

// exports
exports.locals = {
	"advisories-container": "_3azRoMfPxHujgRO09wt3w6",
	"advisoriesContainer": "_3azRoMfPxHujgRO09wt3w6"
};
"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusMain
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_redux_1 = require("react-redux");
const context_1 = require("../../context");
const ECITMBrand_1 = require("../../components/ECITMBrand");
const Cover_1 = require("../../components/Cover");
const mapStateToProps = (state) => {
    return {};
};
/**
 * FocusSearchcontainer component
 *
 * @since 0.1.0
 */
class FocusMain extends React.Component {
    /**
     * Constructor
     *
     * @param { FocusMainProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'FocusMain';
        this.state = {
            session: {
                id: "",
                user: {}
            }
        };
    }
    /**
     * Initialize all kux controls immediately after component mounts
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        // Set the session in state, via the conetx
        this.setState({ session: this.context });
        // Call kux.controls() to instantiate all the UX Goodies
        kux.controls();
        //console.log( 'Called componentDidMount() from FocusSearch' );
    }
    /**
     * Returns the brand image component
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getImageBrand() {
        return (React.createElement(ECITMBrand_1.ECITMBrand, null));
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        return (React.createElement(Cover_1.Cover, { brand: this.getImageBrand() }));
    }
}
exports.FocusMain = FocusMain;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
FocusMain.defaultProps = {};
/**
 * This makes the session
 */
FocusMain.contextType = context_1.SessionContext;
// Here we wrap the FocusSearch Container with a Connect container,
// creating the FocusSearchContainer:
exports.default = react_redux_1.connect(mapStateToProps)(FocusMain);

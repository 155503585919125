"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusLogin
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// EXPORTS
var FocusLogin_1 = require("./FocusLogin");
exports.default = FocusLogin_1.default;
exports.FocusLogin = FocusLogin_1.FocusLogin;

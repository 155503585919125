"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const action_types_1 = require("../constants/action-types");
// DEFINES
const initialState = {
    loading: false,
    error: null,
    user: {
        invalidate: true,
        lastUpdated: null,
        user: []
    }
};
// Create a reducer:
const userVerification = (state = initialState, action) => {
    //console.log( `ACTION_TYPE: ${action.type}` );
    switch (action.type) {
        case action_types_1.VERIFY_USER_BEGIN:
            {
                // The loading state. Render a loader and wipe errors:
                return Object.assign(Object.assign({}, state), { loading: true, error: null });
            }
            break;
        case action_types_1.VERIFY_USER_SUCCESS:
            {
                //console.log( 'Payload:' );
                //console.log( action.payload.user );
                // The success state. We have the API response, render it:.
                return Object.assign(Object.assign({}, state), { loading: false, user: { user: action.payload.user, invalidate: false, lastUpdated: Date.now() } });
            }
            break;
        case action_types_1.VERIFY_USER_ERROR:
            {
                // The error state. Render the error in our simple template app:
                return Object.assign(Object.assign({}, state), { loading: false, error: action.payload.error, user: { user: {}, invalidate: false, lastUpdated: null } });
            }
            break;
        default:
            {
                return state;
            }
            break;
    }
};
// Export it:
exports.default = userVerification;

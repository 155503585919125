"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Cover
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * Cover presentational component
 *
 * @param { CoverProps } props
 *
 * @since 0.1.0
 */
exports.Cover = (props) => {
    // Here we render the component:
    /*
            <NavLink to="/login" className={styles.topLeftQuad}>
            </NavLink>
            <NavLink to="/login" className={styles.topRightQuad}>
            </NavLink>
            <NavLink to="/login" className={styles.bottomRightQuad}>
            </NavLink>
            <NavLink to="/login" className={styles.bottomLeftQuad}>
            </NavLink>
    */
    return (React.createElement("div", { className: _1.styles.coverContainer },
        React.createElement("div", { className: _1.styles.topLeftQuad }),
        React.createElement("div", { className: _1.styles.topRightQuad }),
        React.createElement("div", { className: _1.styles.bottomRightQuad }),
        React.createElement("div", { className: _1.styles.bottomLeftQuad }),
        props.brand));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Cover.displayName = 'Cover';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

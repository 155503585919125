"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusUserVerification
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// EXPORTS
var FocusUserVerification_1 = require("./FocusUserVerification");
exports.default = FocusUserVerification_1.default;
exports.FocusUserVerification = FocusUserVerification_1.FocusUserVerification;

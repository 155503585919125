"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDCheckbox
 * @author:     Richard B Winters
 * @copyright:  2020 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
/**
 * KMDCheckbox presentational component
 *
 * @param { KMDCheckboxProps } props
 *
 * @since 0.1.0
 */
exports.KMDCheckbox = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: "checkbox-container" },
        React.createElement("div", { "data-field": "form", "data-for": ((props.name) ? props.name : ((props.id) ? props.id : "")), className: "mdc-form-field" },
            React.createElement("div", { "data-field": "checkbox", className: "mdc-checkbox" + ((props.touchTarget) ? " mdc-checkbox--touch" : "") },
                React.createElement("input", Object.assign({ type: "checkbox", className: "mdc-checkbox__native-control" }, ((props.id && props.id !== "") ? { id: props.id } : {}), ((props.name && props.name !== "") ? { name: props.name } : {}), ((props.required) ? { required: props.required } : {}), ((props.disabled) ? { disabled: props.disabled } : {}))),
                React.createElement("div", { className: "mdc-checkbox__background" },
                    React.createElement("svg", { className: "mdc-checkbox__checkmark", viewBox: "0 0 24 24" },
                        React.createElement("path", { className: "mdc-checkbox__checkmark-path", fill: "none", d: "M1.73,12.91 8.1,19.28 22.79,4.59" })),
                    React.createElement("div", { className: "mdc-checkbox__mixedmark" })),
                React.createElement("div", { className: "mdc-checkbox__ripple" })),
            React.createElement("label", { htmlFor: props.id }, props.label))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDCheckbox.displayName = 'KMDCheckbox';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Advisories
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * Advisories presentational component
 *
 * @param { AdvisoriesProps } props
 *
 * @since 0.1.0
 */
exports.Advisories = (props) => {
    // Here we render the component:
    return (React.createElement("div", { "data-purpose": "advisories-container", className: _1.styles.advisoriesContainer + " advisories-container" }, props.list));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Advisories.displayName = 'Advisories';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

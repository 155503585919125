"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDAppBarActionItemMenu
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
/**
 * KMDAppBarActionItemMenu presentational component
 *
 * @param { KMDAppBarActionItemMenuProps } props
 *
 * @since 0.1.0
 */
exports.KMDAppBarActionItemMenu = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: "mdc-menu-surface--anchor" },
        React.createElement("a", { className: ((props.iconic) ? "material-icons " : "") + "mdc-top-app-bar__action-item", "data-dropdown": props.id, title: props.linkTitle, "aria-label": props.ariaLabel }, (props.iconic) ? props.icon : props.title),
        React.createElement("div", { className: "mdc-menu mdc-menu-surface", tabIndex: props.tabIndex, "data-menu": "dropdown", "data-for": props.id }, props.list)));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDAppBarActionItemMenu.displayName = 'KMDAppBarActionItemMenu';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

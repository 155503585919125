"use strict";
/*------------------------------------------------------------------------------
 * @package:   ErrorHandler
 * @author:    Richard B Winters
 * @copyright: 2018 Massively Modified, Inc..
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * MessageBoardList presentational component
 *
 * @param { ErrorHandlerProps } props
 *
 * @since 0.1.0
 */
exports.ErrorHandler = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.errorHandlerContainer },
        React.createElement("div", { className: "row " + _1.styles.focusHeader },
            React.createElement("div", { className: "column+12 column+8 column+4" },
                React.createElement("h1", null,
                    React.createElement("span", { className: _1.styles.titleCapture }, props.title)))),
        React.createElement("div", { className: _1.styles.focusContent },
            React.createElement("div", { className: _1.styles.errorWrap + " " + _1.styles.errorMessage },
                React.createElement("h1", null, "Haxxed!?"),
                React.createElement("small", null, "Naw, you wouldn't do that right? :)"),
                React.createElement("p", null,
                    "You must have landed here by mistake! Click ",
                    React.createElement(react_router_dom_1.NavLink, { to: "/" }, "HERE"),
                    " to be returned to the main page.")))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.ErrorHandler.displayName = "ErrorHandler";
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const redux_1 = require("redux");
const advisories_1 = require("./advisories");
const user_authentication_1 = require("./user-authentication");
const user_registration_1 = require("./user-registration");
const user_verification_1 = require("./user-verification");
const users_1 = require("./users");
const dummy_items_1 = require("./dummy-items");
// DEFINES
const rootReducer = redux_1.combineReducers({
    advisories: advisories_1.default,
    userAuthentication: user_authentication_1.default,
    userRegistration: user_registration_1.default,
    userVerification: user_verification_1.default,
    users: users_1.default,
    dummyItems: dummy_items_1.default
});
// Export it:
exports.default = rootReducer;

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const action_types_1 = require("../constants/action-types");
// DEFINES
/* Dispatch Actions for fetching a items from an API: */
exports.submitAdvisory = (advisory) => ({ type: action_types_1.SUBMIT_ADVISORY, payload: { advisory } });
exports.removeAdvisory = (advisory) => ({ type: action_types_1.REMOVE_ADVISORY, payload: { advisory } });

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Massively Modified, Inc..
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
//import { styles } from './';
const React = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const context_1 = require("../../context");
const helpers_1 = require("../../helpers");
const NodeKitCore_1 = require("../../components/NodeKitCore");
const mapStateToProps = (state) => ({
//user: state.user
});
let sessionHelper = new helpers_1.SessionHelper();
/**
* App container component
*
* @since 0.1.0
*/
class App extends React.Component {
    /**
     * Constructor
     *
     * @param { AppProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @vqar { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = "AppContainer";
        this.setSession = () => {
            this.setState((state) => ({
                session: sessionHelper.getSession()
            }));
        };
        this.state = {
            session: sessionHelper.getSession(),
            setSession: this.setSession
        };
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        return (React.createElement(context_1.SessionContext.Provider, { value: this.state },
            React.createElement(react_router_dom_1.HashRouter, null,
                React.createElement(NodeKitCore_1.NodeKitCore, { title: this.props.title }))));
    }
}
exports.App = App;
/**
 * We'd set propTypes here if this wasn't Typescript.
 */
/**
 * @var { any } defaultProps The App's default property values
 *
 * @since 0.1.0
 */
App.defaultProps = {
    title: "Edison Computers"
};
// Here we wrap the MessageBoardCotnainer with a Connect container,
// creating the MessageBoard Container:
exports.default = react_redux_1.connect(mapStateToProps)(App);

var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MIHF7roIsYkzEPv4j4ws0 {\n  position: fixed;\n  left: 50%;\n  transform: translateX(-50%);\n  -ms-transform: translateX(-50%);\n  display: block;\n  margin: 0;\n  padding: 0;\n  width: auto;\n  height: 100%; }\n  .MIHF7roIsYkzEPv4j4ws0 ._35wgrIVUeKLmghLmF5j6Ty {\n    margin: 0 auto;\n    background: transparent url(" + escape(require("../../assets/images/eci-e-logo-128.png")) + ") no-repeat;\n    background-size: contain;\n    width: 128px;\n    height: 128px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    border: none;\n    opacity: 0.25; }\n", ""]);

// exports
exports.locals = {
	"eci-tm-brand_container": "MIHF7roIsYkzEPv4j4ws0",
	"eciTmBrandContainer": "MIHF7roIsYkzEPv4j4ws0",
	"eci-tm-brand_image": "_35wgrIVUeKLmghLmF5j6Ty",
	"eciTmBrandImage": "_35wgrIVUeKLmghLmF5j6Ty"
};
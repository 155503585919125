"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDMenuList
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
/**
 * KMDMenuList presentational component
 *
 * @param { KMDMenuListProps } props
 *
 * @since 0.1.0
 */
exports.KMDMenuList = (props) => {
    // Here we render the component:
    return (React.createElement("ul", { className: "mdc-menu__items mdc-list", role: "menu", "aria-hidden": "true", "aria-orientation": "vertical", tabIndex: props.tabIndex }, props.list));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDMenuList.displayName = 'KMDMenuList';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    AppFooter
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const Footer_1 = require("../../components/Footer");
const mapStateToProps = (state) => {
    return {};
};
/**
 * AppFootercontainer component
 *
 * @since 0.1.0
 */
class AppFooter extends React.Component {
    /**
     * Constructor
     *
     * @param { AppFooterProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'AppFooter';
    }
    /**
     * Gets the left link list for the app footer
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getLinkListLeft() {
        let linkList = [];
        // Link to our Privacy Policy
        linkList.push(React.createElement(react_router_dom_1.NavLink, { key: "link_list_privacy", to: "/privacy", className: "footer-link" }, "Privacy"));
        // Link to our Terms of Service
        linkList.push(React.createElement(react_router_dom_1.NavLink, { key: "link_list_tos", to: "/terms", className: "footer-link" }, "Terms"));
        return linkList;
    }
    /**
     * Gets the right link list for the app footer
     *
     * @param void
     *
     * @return { any }
     *
     * @since 0.1.0
     */
    getLinkListRight() {
        let linkList = [];
        /* Link to LinkedIn https://linkedin.com/company/edison-computers
        linkList.push(
            <a key={"link_list_linked_in"} href="#" target="_blank" className="social-media-icon">
                <div className="linked-in-social-icon"></div>
            </a>
        )
        */
        // Link to Facebook
        linkList.push(React.createElement("a", { key: "link_list_facebook", href: "https://facebook.com/pages/edisoncomputers/", target: "_blank", className: "social-media-icon" },
            React.createElement("div", { className: "facebook-social-icon" })));
        /* Link to Twitter https://twitter.com/edisoncomputers
        linkList.push(
            <a key={"link_list_twitter"} href="#" target="_blank" className="social-media-icon">
                <div className="twitter-social-icon"></div>
            </a>
        )

        // Link to GitLab https://gitlab.com/devrikx
        linkList.push(
            <a key={"link_list_gitlab"} href="#" target="_blank" className="social-media-icon">
                <div className="gitlab-social-icon"></div>
            </a>
        )

        // Link to Github https://github.com/devrikx
        linkList.push(
            <a key={"link_list_github"} href="#" target="_blank" className="social-media-icon">
                <div className="github-social-icon"></div>
            </a>
        )
        */
        return linkList;
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        return (React.createElement(Footer_1.Footer, { copyrightStart: 2015, copyrightEnd: new Date().getFullYear(), copyrightHolder: "Edison Computers, Inc.", linkListLeft: this.getLinkListLeft(), linkListRight: this.getLinkListRight() }));
    }
}
exports.AppFooter = AppFooter;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
AppFooter.defaultProps = {};
// Here we wrap the AppFooter Container with a Connect container,
// creating the AppFooterContainer:
exports.default = react_redux_1.connect(mapStateToProps)(AppFooter);

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    mmod-co/core-react
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
class SessionHelper {
    /**
     * Class constructor
     *
     * @since 0.1.0
     */
    constructor() {
    }
    /**
     * Builds a properly formatted session object for updating the Rreact App's SessionContext
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getSession() {
        let sid = this.getCookie('sid'), user = this.getCookie('user'), session = {};
        try {
            // SID is passed through as an object who's props/values are in string form
            sid = ((sid) ? JSON.parse(sid) : {});
            console.log(`sid in session context helper:`);
            console.log(sid);
            // USER is passed through as a JSON string wrapped in a string???
            user = ((user) ? JSON.parse(user) : {});
            user = ((typeof user === "string") ? JSON.parse(user) : user);
            console.log(`user in session context helper:`);
            console.log(user);
            session = {
                authenticated: ((sid && Object.keys(sid).length !== 0 && sid.constructor === Object) ? ((user && user.email && user.email !== "guest@example.com") ? true : false) : false),
                id: ((sid) ? sid : {}),
                user: {
                    username: ((user && user.username) ? user.username : ((user && !user.username && !user.email) ? 'guest' : '')),
                    email: ((user && user.email) ? user.email : 'guest@example.com'),
                    name: {
                        first: ((user && user.name && user.name.first) ? user.name.first : ((user && (!user.name || !user.name.first) && !user.email) ? 'Guest' : '')),
                        last: ((user && user.name && user.name.last) ? user.name.last : ((user && (!user.name || !user.name.last) && !user.email) ? 'User' : ''))
                    },
                    context: {
                        id: ((user && user.context && user.context.id) ? user.context.id : ''),
                        email: ((user && user.context && user.context.email) ? user.context.email : 'default@example.com')
                    }
                }
            };
        }
        catch (error) {
            console.error(error);
            return {
                authenticated: false,
                id: {},
                user: {
                    username: "Guest",
                    email: "guest@example.com",
                    name: { first: "Guest", last: "User" },
                    context: { id: "", email: "default@example.com" }
                }
            };
        }
        console.log(`session in session context helper:`);
        console.log(session);
        return session;
    }
    /**
     * Parses cookies into a local array
     *
     * @param void
     *
     * @returns { Array<any> }
     *
     * @since 0.1.0
     */
    getCookies() {
        // Create an array of unparsed cookies:
        return this.cookies = document.cookie.split(';');
    }
    /**
     * Gets the value of the first instance of a cookie if it exists, or returns an empty string
     *
     * @param { string } cookie The name or key of the cookie a value is requested for
     *
     * @returns { string }
     *
     * @since 0.1.0
     */
    getCookie(cookie) {
        let matched = (this.getCookies()).filter(item => item.includes(cookie));
        if (matched && matched.length) {
            return matched[0].replace(/(?:(?:^|.*;\s*)(.*)\s*\=\s*([^;]*).*$)|^.*$/, "$2");
        }
        return "";
    }
    /**
     * Checks for the existence of the cookie specified
     *
     * @param { string } cookie The name or key of the cookie existence is being checked for
     *
     * @returns { boolean }
     *
     * @since 0.1.0
     */
    checkCookie(cookie) {
        return this.getCookies().filter((item) => item.trim().startsWith(`${cookie}=`)).length;
    }
    /**
     * Sets a cookie on the document object
     *
     * @param { string } cookie The cookie string being set on the doument's cookie
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    setCookie(cookieName, cookieValue, expireInDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expireInDays * 24 * 60 * 60 * 1000));
        let expires = `expires=${date.toUTCString()}`;
        document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
    }
}
exports.SessionHelper = SessionHelper;

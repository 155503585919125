"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDButton
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * KMDButton presentational component
 *
 * @param { KMDButtonProps } props
 *
 * @since 0.1.0
 */
exports.KMDButton = (props) => {
    let iconMarkup = null;
    if (props.icon) {
        iconMarkup = (React.createElement("i", { className: "material-icons mdc-button__icon", "aria-hidden": "true" }, props.icon));
    }
    // Here we render the component:
    return (React.createElement("button", Object.assign({ className: "mdc-button" + ((props.outlined) ? " mdc-button--outlined" : "") + ((props.raised) ? " mdc-button--raised" : "") + ((props.unelevated) ? " mdc-button-unelevated" : "") + ((props.topAppBar) ? " " + _1.styles.kmdTopAppBarItem : "") }, props.disabled),
        React.createElement("div", { className: "mdc-button__ripple" }),
        iconMarkup,
        React.createElement("span", { className: "mdc-button__label" }, props.label)));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDButton.displayName = 'KMDButton';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const cross_fetch_1 = require("cross-fetch");
const action_types_1 = require("../constants/action-types");
// DEFINES
/* Dispatch Actions for fetching a items from an API: */
exports.fetchUsersBegin = () => ({ type: action_types_1.FETCH_USERS_BEGIN });
exports.fetchUsersSuccess = (users) => ({ type: action_types_1.FETCH_USERS_SUCCESS, payload: { users } });
exports.fetchUsersError = (error) => ({ type: action_types_1.FETCH_USERS_ERROR, payload: { error } });
/**
 * Method to get a list of users from our API via `fetch`
 *
 * @param { void }
 *
 * @return { Promise<any> }
 *
 * @since 0.1.0
 */
function fetchUsers() {
    console.log('Fetching users!');
    return (dispatch) => {
        dispatch(exports.fetchUsersBegin());
        return cross_fetch_1.default("https://www.snagfilms.com/apis/films.json?limit=10")
            .then(handleErrors)
            .then(response => response.json())
            .then((json) => {
            console.log('Dispatching success action: ');
            console.log(json.users);
            dispatch(exports.fetchUsersSuccess(json.users));
            return json.users;
        })
            .catch((error) => dispatch(exports.fetchUsersError(error)));
    };
}
exports.fetchUsers = fetchUsers;
/**
 * A method to handle HTTP Errors
 *
 * @param { any } response The fetch response
 *
 * @return { any } Returns the response if no errors, else it throws the error
 *
 * @since 0.1.0
 */
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

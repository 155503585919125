"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Advisory
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * Advisory presentational component
 *
 * @param { AdvisoryProps } props
 *
 * @since 0.1.0
 */
exports.Advisory = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.advisoryItemContainer + " advisory-item-container" },
        React.createElement("div", { className: _1.styles.advisoryItem + " " + ((props.advisory.isError) ? "on-error" : "") + " advisory-item" },
            React.createElement("div", { className: _1.styles.advisoryItemCloseButton + " advisory-item_close-button" },
                React.createElement("a", { id: props.advisory.id, onClick: props.closeHandler, className: "material-icons " }, "cancel")),
            React.createElement("div", { className: _1.styles.advisoryItemTitle + " advisory-item_title " + (props.advisory.isError ? "error-message-major" : "") }, props.advisory.title),
            React.createElement("div", { className: _1.styles.advisoryItemContent + " advisory-item_content" },
                React.createElement("p", { className: (props.advisory.isError) ? "error-message-minor" : "" }, props.advisory.content)))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Advisory.displayName = 'Advisory';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const action_types_1 = require("../constants/action-types");
// DEFINES
/* An action for adding dummy items */
exports.addDummyItem = (item) => ({ type: action_types_1.ADD_DUMMY_ITEM, payload: item });

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusTerms
 * @author:     Richard B Winters
 * @copyright:  2020 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// EXPORTS
var FocusTerms_1 = require("./FocusTerms");
exports.default = FocusTerms_1.default;
exports.FocusTerms = FocusTerms_1.FocusTerms;

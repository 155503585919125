"use strict";
/*-----------------------------------------------------------------------------
 * @package:    mmod-co/core-react
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// DEFINES
class TimestampHelper {
    /**
     * Class constructor
     *
     * @since 0.1.0
     */
    constructor() {
    }
    /**
     * Builds a properly formatted date/time string
     *
     * @param { number } divisionSinceEpoch The number of seconds since epoch, divided by 1000
     *
     * @return { string } Returns a date/time string formatted as: mm/dd/yy @ hh:mm am|pm
     */
    getTimestamp(sinceEpoch) {
        // Recreate the date and time from our source, which is the number of seconds
        // since epoch:
        let timestamp = new Date(sinceEpoch);
        // now build a formated date and time string:
        let formatted = "";
        // Start with the month:
        formatted += this.checkCharacters(timestamp.getMonth() + 1) + "/";
        // Follow with the day:
        formatted += this.checkCharacters(timestamp.getDate()) + "/";
        // Then the year:
        formatted += this.checkCharacters(timestamp.getFullYear()) + " @ ";
        // Now start the time component with the hours (and convert it from a 24-hour base):
        formatted += this.checkCharacters(((timestamp.getHours() > 12) ? timestamp.getHours() - 12 : timestamp.getHours())) + ":";
        // Follow with the minutes:
        formatted += this.checkCharacters(timestamp.getMinutes()) + " ";
        // And end with the appropriate AM|PM stamp:
        formatted += (timestamp.getHours() > 11) ? "PM" : "AM";
        // Now return our date/time string:
        return formatted;
    }
    /**
     * This method ensures that a date/time string component has the correct # of digits
     *
     * @param { number } value A date/time string component
     *
     * @return { string } A date/time string component with the correct number of digits
     */
    checkCharacters(value) {
        // Convert the number to a string so we can determine its length:
        let fixed = value.toString();
        // If it is a single digit, prepend it with a 0:
        return (fixed.length == 1) ? "0" + fixed : fixed;
    }
}
exports.TimestampHelper = TimestampHelper;

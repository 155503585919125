exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QznwCYtMG6XXJBPuN_NbZ {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: 100%; }\n", ""]);

// exports
exports.locals = {
	"changeMe": "_1QznwCYtMG6XXJBPuN_NbZ"
};
"use strict";
/*------------------------------------------------------------------------------
 * @package:   UnderConstruction
 * @author:    Richard B Winters
 * @copyright: 2018 Massively Modified, Inc..
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const styles = require("./styles.scss");
exports.styles = styles;
var UnderConstruction_1 = require("./UnderConstruction");
exports.UnderConstruction = UnderConstruction_1.UnderConstruction;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    ECITMBrand
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const styles = require("./styles.scss");
exports.styles = styles;
var ECITMBrand_1 = require("./ECITMBrand");
exports.ECITMBrand = ECITMBrand_1.ECITMBrand;

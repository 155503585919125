exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wvw5Dw6buZkgNrQSHWnok {\n  position: relative;\n  margin: 0;\n  padding: 0;\n  display: block;\n  width: 100%;\n  height: 100%;\n  text-align: justify; }\n", ""]);

// exports
exports.locals = {
	"terms-container": "_3wvw5Dw6buZkgNrQSHWnok",
	"termsContainer": "_3wvw5Dw6buZkgNrQSHWnok"
};
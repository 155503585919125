"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDMenuListItem
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * KMDMenuListItem presentational component
 *
 * @param { KMDMenuListItemProps } props
 *
 * @since 0.1.0
 */
exports.KMDMenuListItem = (props) => {
    // Here we render the component:
    return (React.createElement("li", { className: "mdc-list-item", role: "menuitem", tabIndex: props.tabIndex }, ((props.icon) ?
        React.createElement(react_router_dom_1.NavLink, Object.assign({ to: props.link }, (props.async) ? { "data-request": "navigation" } : {}, { className: "drop-down_item", title: props.linkTitle, "aria-label": props.ariaLabel }),
            React.createElement("i", { className: "material-icons drop-down_item--icon" }, props.icon),
            React.createElement("span", { className: "drop-down_item--text" }, props.title))
        :
            React.createElement(react_router_dom_1.NavLink, Object.assign({ to: props.link }, (props.async) ? { "data-request": "navigation" } : {}, { className: "drop-down_item", title: props.linkTitle, "aria-label": props.ariaLabel }), props.title))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDMenuListItem.displayName = 'KMDMenuListItem';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    UserRegistration
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * UserRegistration presentational component
 *
 * @param { UserRegistrationProps } props
 *
 * @since 0.1.0
 */
exports.UserRegistration = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.userRegistrationContainer },
        React.createElement("div", { className: "mdc-card" },
            React.createElement("div", { className: "feature-title+embedded" },
                React.createElement("i", { className: "feature-title_text-icon material-icons" }, "person_add"),
                React.createElement("span", { className: "feature-title_text" }, " \u00A0REGISTER")),
            React.createElement("div", { className: "content-wrapper +split" },
                React.createElement("div", { className: "inline-content +left" },
                    React.createElement("div", { className: "feature-bullet_list" },
                        React.createElement("div", { className: "feature-bullet portfolio" },
                            React.createElement("div", { className: "feature-bullet_title" },
                                React.createElement("i", { className: "feature-bullet_title-icon material-icons" }, "collections"),
                                React.createElement("div", { className: "feature-bullet_title-text" }, "Portfolio Management")),
                            React.createElement("p", null, "The Portfolio delivers a unique and comprehensive tool for exhibiting intellectual property through a robust and responsive interface.")),
                        React.createElement("div", { className: "feature-bullet blog" },
                            React.createElement("div", { className: "feature-bullet_title" },
                                React.createElement("i", { className: "feature-bullet_title-icon material-icons" }, "forum"),
                                React.createElement("div", { className: "feature-bullet_title-text" }, "Blog")),
                            React.createElement("p", null, "The blogging platform offers freedom of expression through a seamless interface designed for developers, by developers.")),
                        React.createElement("div", { className: "feature-bullet social-media" },
                            React.createElement("div", { className: "feature-bullet_title" },
                                React.createElement("i", { className: "feature-bullet_title-icon material-icons" }, "public"),
                                React.createElement("div", { className: "feature-bullet_title-text" }, "Social Media")),
                            React.createElement("p", null, "Social integrations make sharing news and updates with your followers as simple as posting a new blog or portfolio item.")))),
                React.createElement("div", { className: "inline-content +right" },
                    React.createElement("div", { className: "form-wrapper" },
                        React.createElement("form", { onSubmit: props.handleSubmit },
                            React.createElement("div", { className: _1.styles.userRegistrationInputs },
                                React.createElement("div", { className: "form-group account-details" },
                                    React.createElement("div", { className: "input-group" },
                                        React.createElement("div", { className: _1.styles.emailInput }, props.email),
                                        React.createElement("div", { className: "input-validation form-control-feedback " + props.emailValidation.validation.class, "data-validation": "input" }, props.emailValidation.validation.message)),
                                    React.createElement("div", { className: "input-group" },
                                        React.createElement("div", { className: _1.styles.passwordInput }, props.password),
                                        React.createElement("div", { className: "input-validation form-control-feedback " + props.passwordValidation.validation.class, "data-validation": "input" }, props.passwordValidation.validation.message)),
                                    React.createElement("div", { className: "input-group" },
                                        React.createElement("div", { className: _1.styles.confirmPasswordInput }, props.confirmPassword),
                                        React.createElement("div", { className: "input-validation form-control-feedback " + props.confirmPasswordValidation.validation.class, "data-validation": "input" }, props.confirmPasswordValidation.validation.message))),
                                React.createElement("div", { className: _1.styles.registrationButton },
                                    React.createElement("button", { type: "submit", className: _1.styles.registrationButtonText + " mdc-button mdc-button--raised" }, "Submit"),
                                    React.createElement("button", { className: _1.styles.cancelRegistrationButtonText + " mdc-button mdc-button--raised" }, "Cancel"))))))))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.UserRegistration.displayName = 'UserRegistration';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

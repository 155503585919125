"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDCheckbox
 * @author:     Richard B Winters
 * @copyright:  2020 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const styles = require("./styles.scss");
exports.styles = styles;
var KMDCheckbox_1 = require("./KMDCheckbox");
exports.KMDCheckbox = KMDCheckbox_1.KMDCheckbox;

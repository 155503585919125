"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// DEFINES
// CONSTANTS
/*
    ADVISORIES
*/
// An action for adding an advisory to the list of advisories being displayed:
exports.SUBMIT_ADVISORY = "SUBMIT_ADVISORY";
// An action for removing an advisory from the list of advisories being displayed:
exports.REMOVE_ADVISORY = "REMOVE_ADVISORY";
/*
    REGISTER
*/
// The loading state, an action for displaying a loader and wiping errors:
exports.REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
// The success state, an action for setting user as registered:
exports.REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
// The failure state, an action for displaying errors:
exports.REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
/*
    VERIFY USER REGISTRATION
*/
// The loading state, an action for displaying a loader and wiping errors:
exports.VERIFY_USER_BEGIN = "SIGNIN_USER_BEGIN";
// The success state, an action for setting user as verified:
exports.VERIFY_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
// The failure state, an action for displaying errors:
exports.VERIFY_USER_ERROR = "SIGNIN_USER_ERROR";
/*
    SIGN IN
:*/
// The loading state, an action for displaying a loader and wiping errors:
exports.SIGNIN_USER_BEGIN = "SIGNIN_USER_BEGIN";
// The success state, an action for setting user as signed-in:
exports.SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
// The failure state, an action for displaying errors:
exports.SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";
/*
    SIGN OUT
 */
// The loading state, an action for displaying a loader and wiping errors:
exports.SIGNOUT_USER_BEGIN = "SIGNOUT_USER_BEGIN";
// The success state, an action for setting user as signed-out:
exports.SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
// The failure state, an action for displaying errors:
exports.SIGNOUT_USER_ERROR = "SIGNOUT_USER_ERROR";
/*
    FETCH USERS
 */
// The loading state, an action for displaying a loader and wiping errors:
exports.FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
// The success state, an action for displaying API items:
exports.FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
// The failure state, an action for displaying errors:
exports.FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
/*
    ADD A DUMMY ITEM TO A LIST
 */
// Constants for working with dummy items that will be displayed
exports.ADD_DUMMY_ITEM = "ADD_DUMMY_ITEM";

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    edison-computers-com/core-react
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0..0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const ReactDOM = require("react-dom");
const react_redux_1 = require("react-redux");
const index_1 = require("./redux/store/index");
const App_1 = require("./containers/App");
// DEFINES
window.store = index_1.default;
// Render the application:
ReactDOM.render(
// Render our application's root component, wrapped with the react-redux provider component:
React.createElement(react_redux_1.Provider, { store: index_1.default },
    React.createElement(App_1.App, null)), document.getElementById("nodekit-app"));

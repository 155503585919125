"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Privacy
 * @author:     Richard B Winters
 * @copyright:  2020 Edison Computers, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * Privacy presentational component
 *
 * @param { PrivacyProps } props
 *
 * @since 0.1.0
 */
exports.Privacy = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.privacyContainer },
        React.createElement("article", { className: "featured_article" },
            React.createElement("div", { className: "article_header" },
                React.createElement("div", { className: "article_header+wider" },
                    React.createElement("h1", null, "Privacy Policy"),
                    React.createElement("p", null, "The edisoncomputers.com privacy policy.")),
                React.createElement("div", { className: "article_header+related" },
                    React.createElement("span", { className: "content_related-list+title" }, "Contents"),
                    React.createElement("ul", { className: "content_related-list" },
                        React.createElement("li", null, "List Item 1"),
                        React.createElement("li", null, "List Item 2")))),
            React.createElement("hr", null),
            React.createElement("div", { id: "mmod-privacy-policy", className: "article_header+sub" },
                React.createElement("h6", null, "Edison Computers' privacy practices ensure the confidentiality of user data."),
                React.createElement("p", null, "The following document describes how Edison Computers collects and makes use of user data and personal information. It is Edison Computers' mission to ensure the security and privacy of our users, and providing for the end-user our practices regarding data collection and retention allows our end-users to understand just how serious we take their privacy.")),
            React.createElement("hr", null),
            React.createElement("div", { className: "article_body" },
                React.createElement("section", { id: "privacy-policy", className: "article_section" },
                    React.createElement("h2", { className: "article_chapter-title" }),
                    React.createElement("div", { className: "section_body" },
                        React.createElement("div", { className: "fragment +collapsible", "data-collapsible": "the-policy", "data-collapsed": "false", "aria-expanded": "true" },
                            React.createElement("div", { className: "collapsible_capsule" },
                                React.createElement("button", { "aria-label": "Collapse Content", "data-collapse": "the-policy", className: "collapsible_toggle toggle-button" }),
                                React.createElement("h3", { id: "the-policy", className: "fragment_heading" }, "The Policy")),
                            React.createElement("div", { className: "fragment_wrap", "data-collapsible-wrap": "the-policy" },
                                React.createElement("div", { className: "fragment_hint", "data-collapsible-hint": "the-policy" },
                                    React.createElement("p", null, "The Edison Computers Privacy Policy shares our practices regarding the collection and retentiong of user information.")),
                                React.createElement("div", { className: "fragment_content", "data-collapsible-content": "the-policy" },
                                    React.createElement("div", { "data-markdown": "render", "data-rendered": "false" },
                                        `# PRIVACY POLICY \n` +
                                            `\n` +
                                            `Protecting your private information is our priority. This Statement of Privacy applies to http://edisoncomputers.com and Edison Computers, inc. and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Edison Computers, Inc. include http://edisoncomputers.com, and ECI. The ECI website is a social and professional network site. By using the ECI website, you consent to the data practices described in this statement. \n` +
                                            `\n` +
                                            `## Collection of your Personal Information \n` +
                                            `\n` +
                                            `In order to better provide you with products and services offered on our Site, ECI may collect personally identifiable information, such as your: \n` +
                                            `\n` +
                                            `* First and Last Name \n` +
                                            `* Mailing Address \n` +
                                            `* E-mail Address \n` +
                                            `* Phone Number \n` +
                                            `* Employer \n` +
                                            `* Job Title \n` +
                                            `* Social Network Identities \n` +
                                            `\n` +
                                            `If you purchase ECI's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction(s). \n` +
                                            `\n` +
                                            `ECI may also collect anonymous demographic information, wh ich is not unique to you, such as your: \n` +
                                            `\n` +
                                            `* Age \n` +
                                            `* Gender \n` +
                                            `\n` +
                                            `Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through ECI's public message boards, this information may be collected and used by others. \n` +
                                            `\n` +
                                            `We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third partiese; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. \n` +
                                            `\n` +
                                            `## Use of your Personal Information \n` +
                                            `\n` +
                                            `ECI collects and uses your personal information to operate its website(s) and deliver the services you have requested. \n` +
                                            `\n` +
                                            `ECI may also use your personally identifiable information to inform you of other products or services available from ECI and its affiliates. \n` +
                                            `\n` +
                                            `## Sharing Information with Third Parties \n` +
                                            `\n` +
                                            `ECI does not sell, rent or lease its customer lists to third parties. \n` +
                                            `\n` +
                                            `ECI may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. ECI may share data with trusted partners to  help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to ECI, and they are required to maintain the confidentiality of your information. \n` +
                                            `\n` +
                                            `ECI may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on ECI ord the Site; (b) protect and defend the rigts or property of ECI; and/or (c) act under exigent circumstances to protect the personal safety of users of ECI, or the public. \n` +
                                            `\n` +
                                            `## Tracking User Behavior \n` +
                                            `\n` +
                                            `ECI may keep track of the websites and pages our users visit with ECI, in order to determine what ECI services are the most popular. This data is used to deliver customized content and advertising within ECI to customers who behavior indicates that they are interested in a particular subject area. \n` +
                                            `\n` +
                                            `## Automatically Collected Information \n` +
                                            `\n` +
                                            `Information about your computer hardware and software may be automatically collected by ECI. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the ECI website. \n` +
                                            `\n` +
                                            `## Use of Cookies \n` +
                                            `\n` +
                                            `The ECI website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. \n` +
                                            `\n` +
                                            `One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize ECI pages, or register with ECI site or services, a cookie  helps ECI to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same ECI website, the information you previously provided can be retrieved, so you can easily use the ECI features that you customized. \n` +
                                            `\n` +
                                            `You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of ECI services or websites you visit. \n` +
                                            `\n` +
                                            `## Links \n` +
                                            `\n` +
                                            `This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our s ite and to read the privacy statements of any other site that collects personally identifiable information. \n` +
                                            `\n` +
                                            `## Security of your Personal Information \n` +
                                            `\n` +
                                            `ECI secures your personal information from unauthorized access, use, or disclosure. ECI uses the following methods for this purpose: \n` +
                                            `\n` +
                                            `* SSL Protocol \n` +
                                            `* GnuPG Encryption (where applicable) \n` +
                                            `\n` +
                                            `When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. \n` +
                                            `\n` +
                                            `We strive to take appriate security measures to protect against unauthorizeed access to or alteration of your personal information. Unfortunately, no data transmission over the Internate or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to prtect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. \n` +
                                            `\n` +
                                            `## Children Under Thirdteen \n` +
                                            `\n` +
                                            `ECI does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. \n` +
                                            `\n` +
                                            `## Disconnecting your ECI Account from Third Party Websites \n` +
                                            `\n` +
                                            `You will be able to connect your ECI account to third party accounts. BY CONNECTING YOUR ECI ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users may learn how to disconnect their accounts from third-party websites by visiting their "My Account" page. Users may also contact us via email. \n` +
                                            `\n` +
                                            `## E-mail Communications \n` +
                                            `\n` +
                                            `From time to time, ECI may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from ECI or click on a link therein. \n` +
                                            `\n` +
                                            `If you would like to stop receiving marketing or promotional communications via email from ECI, you may opt out of such communications by clicking on the UNSUBSCRIBE button, or by visiting your "My Account" page. \n` +
                                            `\n` +
                                            `## External Data Storage Sites \n` +
                                            `\n` +
                                            `We may store your data on servers provided by third party hosting vendors with whom we have contracted. \n` +
                                            `\n` +
                                            `## Changes to this Statement \n` +
                                            `\n` +
                                            `ECI reserves the right to chagne this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our sites, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgement of the modified Privacy Plicy; and (b) agreement to abide and be bound by that policy. \n` +
                                            `\n` +
                                            `## Contact Information \n` +
                                            `\n` +
                                            `ECI welcomes your questions or comments regarding this Statement of Privacy.  If you believe that ECI has not adhered to this Statement, please contact ECI at: \n` +
                                            `\n` +
                                            `**Edison Computers, Inc.** \n`,
                                        " ",
                                        React.createElement("br", null),
                                        `5375 State Highway 7 \n`,
                                        " ",
                                        React.createElement("br", null),
                                        `Suite D \n`,
                                        " ",
                                        React.createElement("br", null),
                                        `Oneonta, NY 13820 \n` +
                                            `\n` +
                                            `**Email Address:** \n`,
                                        " ",
                                        React.createElement("br", null),
                                        `[support@edisoncomputers.com](mailto:support@edisoncomputers.com "ECI Support") \n`,
                                        " ",
                                        React.createElement("br", null),
                                        `[ahren.edison@edisoncomputers.com](mailto:ahren.edison@edisoncomputers.com "ECI Support") \n` +
                                            `\n` +
                                            `**Telephone number:** \n` +
                                            `+1 (607) 432-9311 \n` +
                                            `\n` +
                                            `***Effective as of December 14, 2019*** \n` +
                                            `\n`)))))))),
        React.createElement("button", { className: "mdc-fab material-icons app-fab--absolute", "aria-label": "Scroll To Top", "data-scroll-to-target": "top" },
            React.createElement("span", { className: "mdc-fab__icon" }, "keyboard_arrow_up"))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Privacy.displayName = 'Privacy';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDAppBarActionItem
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * KMDAppBarActionItem presentational component
 *
 * @param { KMDAppBarActionItemProps } props
 *
 * @since 0.1.0
 */
exports.KMDAppBarActionItem = (props) => {
    // Here we render the component:
    return (React.createElement(react_router_dom_1.NavLink, Object.assign({ to: props.link }, (props.async) ? { "data-request": "navigation" } : {}, { className: ((props.iconic) ? "material-icons " : "") + "mdc-top-app-bar__action-item", title: props.linkTitle, "aria-label": props.ariaLabel }), props.title));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDAppBarActionItem.displayName = 'KMDAppBarActionItem';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDSelect
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * KMDSelect presentational component
 *
 * @param { KMDSelectProps } props
 *
 * @since 0.1.0
 */
exports.KMDSelect = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.mdcSelectContainer },
        React.createElement("div", { ref: props.selectRefCallBack, className: "mdc-select" + ((props.outlined) ? " mdc-select--outlined" : ""), "data-field": "select" },
            React.createElement("div", { className: "mdc-select__anchor" },
                React.createElement("i", { className: "mdc-select__dropdown-icon" }),
                React.createElement("div", { className: "mdc-select__selected-text", role: "button", "aria-haspopup": "listbox", "aria-labelledby": props.label }),
                (props.outlined) ?
                    React.createElement("div", { className: "mdc-notched-outline" },
                        React.createElement("div", { className: "mdc-notched-outline__leading" }),
                        React.createElement("div", { className: "mdc-notched-outline__notch" },
                            React.createElement("span", { className: "mdc-floating-label" + ((props.preSelected) ? " mdc-floating-label--float-above" : "") }, props.label)),
                        React.createElement("div", { className: "mdc-notched-outline__trailing" })) :
                    React.createElement("span", { className: "mdc-floating-label" + ((props.preSelected) ? " mdc-floating-label--float-above" : "") }, props.label),
                (props.outlined) ? '' : React.createElement("div", { className: "mdc-line-ripple" })),
            React.createElement("div", { className: "mdc-select__menu mdc-menu mdc-menu-surface", role: "listbox" },
                React.createElement("ul", { className: "mdc-list" }, props.list))),
        React.createElement("input", { ref: props.hiddenRefCallBack, type: "hidden", id: props.name, name: props.name })));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDSelect.displayName = 'KMDSelect';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const redux_1 = require("redux");
const redux_thunk_1 = require("redux-thunk");
const index_1 = require("../reducers/index");
// DEFINES
const store = redux_1.createStore(index_1.default, redux_1.applyMiddleware(redux_thunk_1.default));
// Let's export our redux store:
exports.default = store;

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const cross_fetch_1 = require("cross-fetch");
const action_types_1 = require("../constants/action-types");
const actions_1 = require("../actions");
const helpers_1 = require("../../helpers");
// DEFINES
let sessionHelper = new helpers_1.SessionHelper();
/* Dispatch Actions for signing a user in via API: */
exports.signInUserBegin = () => ({ type: action_types_1.SIGNIN_USER_BEGIN });
exports.signInUserSuccess = (user) => ({ type: action_types_1.SIGNIN_USER_SUCCESS, payload: { user } });
exports.signInUserError = (error) => ({ type: action_types_1.SIGNIN_USER_ERROR, payload: { error } });
/**
 * Method to sign in a user through our API via `fetch`
 *
 * @param { any } credentials The username and password used to sign in a user
 * @param { any } history The React-Redux-Router history object (for forwarding)
 *
 * @return { Promise<any> }
 *
 * @since 0.1.0
 */
function signInUser(credentials, history, sessionContext) {
    console.log('Authenticating user!');
    return (dispatch) => {
        dispatch(exports.signInUserBegin());
        return cross_fetch_1.default("/api/user/login", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "apiVersion": "1.0",
                "method": "user.login",
                "params": credentials
            })
        })
            .then(response => handleErrors(response, dispatch))
            //.then( response => response.json() )
            .then(
        // Finally let us leave the user in a position to verify their account:
        (authentication) => {
            // Dispatch our success action, and throw the user data into our store:
            dispatch(exports.signInUserSuccess(authentication.data.items));
            // Update the SessionContext:
            sessionContext.setSession();
            // Then redirect the user to the verification route:
            history.push(`/`);
            // Finally, return the fetch promise object:
            return authentication;
        })
            .catch((error) => dispatch(exports.signInUserError(error)));
    };
}
exports.signInUser = signInUser;
/* Dispatch Actions for signing a user out via API: */
exports.signOutUserBegin = () => ({ type: action_types_1.SIGNOUT_USER_BEGIN });
exports.signOutUserSuccess = (user) => ({ type: action_types_1.SIGNOUT_USER_SUCCESS, payload: { user } });
exports.signOutUserError = (error) => ({ type: action_types_1.SIGNOUT_USER_ERROR, payload: { error } });
/**
 * Method to sign out a user through our API via `fetch`
 *
 * @param { void }
 *
 * @return { Promise<any> }
 *
 * @since 0.1.0
 */
function signOutUser(history) {
    console.log('Signing out user!');
    return (dispatch) => {
        dispatch(exports.signOutUserBegin());
        return cross_fetch_1.default("/api/user/logout", {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "apiVersion": "1.0",
                "method": "user.logout",
                "params": ""
            })
        })
            .then(response => handleErrors(response, dispatch))
            //.then( response => response.json() )
            .then(
        // Finally let us leave the user in a position to verify their account:
        (response) => {
            // Dispatch our success action, and throw the user data into our store:
            dispatch(exports.signOutUserSuccess(response.data.messages));
            // Then redirect the user to the verification route:
            history.push(`/`);
            // Finally, return the fetch promise object:
            return response;
        })
            .catch((error) => dispatch(exports.signOutUserError(error)));
    };
}
exports.signOutUser = signOutUser;
/**
 * A method to handle HTTP Errors
 *
 * @param { any } response The fetch response
 *
 * @return { any } Returns the response if no errors, else it throws the error
 *
 * @since 0.1.0
 */
function handleErrors(response, dispatch) {
    // Define a method to parse the response object (it'll be JSON):
    let parsedResponse = (response) => response.json();
    // Then return a series of promises that run off the defined method's return:
    return parsedResponse(response)
        .then(// Handle the error by submitting an advisory to alert the user:
    (authentication) => {
        if (authentication && authentication.error && authentication.error.code && authentication.error.code < 500) {
            let ts = new Date();
            dispatch(actions_1.submitAdvisory({
                id: `${(authentication.error.errors[0].reason).replace('_', ' ').replace(/[\[\]]+/g, '')}`,
                title: `${(authentication.error.errors[0].reason).replace('_', ' ').replace(/[\[\]]+/g, '')} ${ts.getTime()}`,
                content: `${authentication.error.message}: '${authentication.error.errors[0].message}'.`,
                isError: true
            }));
        }
        return authentication;
    })
        .then(
    // Decide how to handle errors:
    (authentication) => {
        if (authentication && authentication.error && authentication.error.code && authentication.error.code > 499) {
            throw Error(authentication);
        }
        // Otherwise let's return the response to the action method
        // to be processed further (bad requests yield advisories):
        return authentication;
    });
}

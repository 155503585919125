"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const action_types_1 = require("../constants/action-types");
// DEFINES
const initialState = {
    dummyItems: []
};
const dummyItems = (state = initialState, action) => {
    //console.log( `ACTION_TYPE: ${action.type}` );
    switch (action.type) {
        case action_types_1.ADD_DUMMY_ITEM:
            {
                // Return a new state object that is a copy of the original state
                // plus the additions present in the payload:
                return Object.assign(Object.assign({}, state), { dummyItems: [...state.dummyItems, action.payload] });
            }
            break;
        default:
            {
                return state;
            }
            break;
    }
};
// Export it:
exports.default = dummyItems;

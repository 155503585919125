"use strict";
/*-----------------------------------------------------------------------------
 * @package:    AppBar
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const context_1 = require("../../context");
const TopAppBar_1 = require("../../components/MaterialDesign/TopAppBar");
const KMDButton_1 = require("../../components/MaterialDesign/KMDButton");
const KMDMenuList_1 = require("../../components/MaterialDesign/KMDMenuList");
const KMDMenuListItem_1 = require("../../components/MaterialDesign/KMDMenuListItem");
const KMDAppBarActionItem_1 = require("../../components/MaterialDesign/KMDAppBarActionItem");
const KMDAppBarActionItemMenu_1 = require("../../components/MaterialDesign/KMDAppBarActionItemMenu");
const mapStateToProps = (state) => {
    return {};
};
/**
 * AppBarcontainer component
 *
 * @since 0.1.0
 */
class AppBar extends React.Component {
    /**
     * Constructor
     *
     * @param props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'AppBar';
    }
    /**
     * Method which runs once a omponent has mounted
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        console.log(`componentDidMount() in AppBar container says context: `);
        console.log(this.context);
    }
    /**
     * Method which runs once a omponent has updated
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidUpdate() {
        console.log(`componentDidUpdate() in AppBar container says context: `);
        console.log(this.context);
    }
    /**
     * Method to populate the topAppBar
     */
    getAppBarItems() {
        // Create the left menu container used to populate the AppBar:
        /**
         * @var { KMDAppBarActionItemMenu | KMDAppBarActionItem }
         */
        let menuLeft = [];
        // Next populate the left side (nothing for now):
        // Finally, get our userMenu (which differs based on sign-in status):
        let userMenu = this.getAppBarUserMenu();
        // For now just return the userMenu (later we'll populate a left-side as well):
        return userMenu;
    }
    /**
     * Method to generate a user menu if a user is authenticated
     */
    getAppBarUserMenu() {
        // Create the container which will store the user menu portion:
        console.log("rebuilding the Appbar menu");
        console.log(this.context);
        /**
         * @var { KMDAppBarActionItemMenu | KMDAppBarActionItem }
         */
        let userMenu = [];
        if (this.context.session && this.context.session.authenticated) {
            // Next, if the user is signed-in there should be a drop-down
            // menu (of MDC Menu type)
            //
            // Otherwise there will only be AppBarActionItems, that
            // direct one to log in - among other things.
            // Create the entry point to the userActionItemMenu drop-down:
            let userActionItemMenu = {
                id: "userActionItemMenu",
                title: "Account",
                linkTitle: "User Menu",
                ariaLabel: "User Menu",
                tabIndex: -1,
                iconic: true,
                icon: "account_circle",
                list: null
            };
            // And populate it:
            let userMenuList = [];
            // Start with the account item:
            userMenuList.push(React.createElement(KMDMenuListItem_1.KMDMenuListItem, { key: 1, id: "account", title: "Account", linkTitle: "View/Edit Account Settings", ariaLabel: "View/Edit Account Settings", tabIndex: 0, icon: "perm_contact_calendar", link: "/user/account" }));
            // Continue with the preferences item:
            userMenuList.push(React.createElement(KMDMenuListItem_1.KMDMenuListItem, { key: 2, id: "settings", title: "Settings", linkTitle: "View/Edit Admin Settings", ariaLabel: "View/Edit Admin Settings", tabIndex: 0, icon: "settings_applications", link: "/user/settings" }));
            // Continue with the sign-out:
            userMenuList.push(React.createElement("li", { className: "drop-down_item" },
                React.createElement("hr", { className: "menu-item_list--divider" })));
            // Continue with the sign-out:
            userMenuList.push(React.createElement(KMDMenuListItem_1.KMDMenuListItem, { key: 4, id: "logout", title: "Log out", linkTitle: "Log out", ariaLabel: "Log out", tabIndex: 0, icon: "exit_to_app", link: "/logout" }));
            // Then we must add the menu item list to the 'menu' portion
            // of the drop-down control:
            let userMenuItemList = (React.createElement(KMDMenuList_1.KMDMenuList, { list: userMenuList, tabIndex: -1 }));
            // Finally, the 'menu' portion of the userMenu, populated with the
            // individual menu items - get's fed to the drop-down button
            // component props as it's 'list' property:
            userActionItemMenu.list = userMenuItemList;
            // Next create the various individual action items:
            // If not signed in, allow users to create an account by starting with
            // creating a portfolio::
            let blogActionItem = (React.createElement(KMDAppBarActionItem_1.KMDAppBarActionItem, { key: 0, id: "blogActionItem", title: "create", linkTitle: "Write a Blog Post", ariaLabel: "Write a Blog Post", link: "/content/add", iconic: true }));
            // We will show notifications if signed-in:
            let notificationActionItem = (React.createElement(KMDAppBarActionItem_1.KMDAppBarActionItem, { key: 1, id: "notificationActionItem", title: "notification_important", linkTitle: "Notifications", ariaLabel: "Notifications", link: "/user/notifications", iconic: true }));
            // Create an account, start with a portfolio:
            userMenu.push(blogActionItem);
            // Notifications:
            userMenu.push(notificationActionItem);
            // Add the userMenu drop-down as a member of our control
            // collection for the topAppBar
            userMenu.push(React.createElement(KMDAppBarActionItemMenu_1.KMDAppBarActionItemMenu, Object.assign({ key: 2 }, userActionItemMenu)));
        }
        else {
            /**
             *  @var { KMDButton }
             */
            let loginButtonItem = (React.createElement(react_router_dom_1.NavLink, { key: 0, to: "/login" },
                React.createElement(KMDButton_1.KMDButton, { label: "Log in", outlined: false, raised: false, unelevated: false, disabled: false, icon: "exit_to_app", topAppBar: true })));
            /**
             *  @var { KMDButton }
             */
            let registerButtonItem = (React.createElement(react_router_dom_1.NavLink, { key: 1, to: "/register" },
                React.createElement(KMDButton_1.KMDButton, { label: "Register", outlined: false, raised: false, unelevated: false, disabled: false, icon: "how_to_reg", topAppBar: true })));
            /* LETS DISPLAY ALL THE USER RELATED ITEMS FOR TESTING PURPOSES! */
            // Start by displaying the various user related menu items:
            userMenu.push(loginButtonItem);
            userMenu.push(registerButtonItem);
        }
        return userMenu;
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @since 0.1.0
     */
    render() {
        return (React.createElement(TopAppBar_1.TopAppBar, { title: this.props.title, menuRight: this.getAppBarItems() }));
    }
}
exports.AppBar = AppBar;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
AppBar.defaultProps = {};
/**
 * @var { SessionContext } contextType The AppBar component is a subscriber of the SessionContext
 *
 * @since 0.1.0
 */
AppBar.contextType = context_1.SessionContext;
// Here we wrap the AppBar Container with a Connect container,
// creating the AppBarContainer:
exports.default = react_redux_1.connect(mapStateToProps)(AppBar);

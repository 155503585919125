"use strict";
/*-----------------------------------------------------------------------------
 * @package:    UserVerification
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
/**
 * UserVerification presentational component
 *
 * @param { UserVerificationProps } props
 *
 * @since 0.1.0
 */
exports.UserVerification = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.userVerificationContainer },
        props.brand,
        React.createElement("form", { onSubmit: props.handleSubmit },
            React.createElement("div", { className: _1.styles.userVerificationInputs },
                React.createElement("div", { className: "form-group verification-code" },
                    React.createElement("div", { className: "input-group" },
                        React.createElement("div", { className: _1.styles.verificationCodeInput }, props.verification),
                        React.createElement("div", { className: "input-validation form-control-feedback " + props.verificationValidation.validation.class, "data-validation": "input" }, props.verificationValidation.validation.message),
                        React.createElement("input", { type: "hidden", value: props.email }))),
                React.createElement("div", { className: _1.styles.verificationButton },
                    React.createElement("button", { type: "submit", className: _1.styles.verificationButtonText + " mdc-button mdc-button--raised" }, "Submit"),
                    React.createElement("button", { className: _1.styles.cancelVerificationButtonText + " mdc-button mdc-button--raised" }, "Cancel"))))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.UserVerification.displayName = 'UserVerification';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

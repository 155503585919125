exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wiFGVTl5NL2hRZhG4tY5Y {\n  color: white; }\n  ._3wiFGVTl5NL2hRZhG4tY5Y i.material-icons {\n    color: white; }\n  ._3wiFGVTl5NL2hRZhG4tY5Y span.mdc-button__label {\n    color: white; }\n", ""]);

// exports
exports.locals = {
	"kmd-top-app-bar_item": "_3wiFGVTl5NL2hRZhG4tY5Y",
	"kmdTopAppBarItem": "_3wiFGVTl5NL2hRZhG4tY5Y"
};
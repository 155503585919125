"use strict";
/*-----------------------------------------------------------------------------
 * @package:    mmod-co/core-react
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
// DEFINES
exports.SessionContext = React.createContext({
    session: {
        id: {},
        user: {
            username: "Guest",
            email: "guest@example.com",
            name: { first: "Guest", last: "User" },
            context: { id: "", email: "default@example.com" }
        }
    },
    setSession: () => { }
});
exports.SessionContext.displayName = "UserSession";

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusUserRegistration
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const actions_1 = require("../../redux/actions");
const helpers_1 = require("../../helpers");
const ECIBrand_1 = require("../../components/ECIBrand");
const UserRegistration_1 = require("../../components/UserRegistration");
const KMDTextField_1 = require("../../components/MaterialDesign/KMDTextField");
const KMDSelect_1 = require("../../components/MaterialDesign/KMDSelect");
const KMDSelectOption_1 = require("../../components/MaterialDesign/KMDSelectOption");
const mapStateToProps = (state) => ({
    user: state.user,
    advisories: state.advisories
});
const mapDispatchToProps = (dispatch) => ({
    submitAdvisory: (advisory) => dispatch(actions_1.submitAdvisory(advisory)),
    signInUser: (credentials, history, sessionContext) => dispatch(actions_1.signInUser(credentials, history, sessionContext)),
    signOutUser: (history) => dispatch(actions_1.signOutUser(history)),
    registerUser: (accountInformation, history) => dispatch(actions_1.registerUser(accountInformation, history))
});
/**
 * FocusUserRegistrationcontainer component
 *
 * @since 0.1.0
 */
class FocusUserRegistration extends React.Component {
    /**
     * Constructor (  )
     *
     * @param { FocusUserRegistrationProps|any } props
     *
     * @since 0.1.0
     */
    constructor(props /*FocusUserRegistrationProps*/) {
        // ^ props type ^ MUST be <any> ^ to connect ^ 'withRouter' when
        // exporting our default export (bottom of our file)...
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'FocusUserRegistration';
        this.state = {
            userType: null,
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            formValid: false,
            validations: {
                username: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                password: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                confirmPassword: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                email: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                confirmEmail: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                firstName: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                },
                lastName: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                }
            }
        };
        this.handleFormFieldInput = this.handleFormFieldInput.bind(this);
        this.handleUserTypeSelectChange = this.handleUserTypeSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Method which runs once a omponent has mounted
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        // Call kux.controls() to instantiate all the UX Goodies
        kux.controls();
        // Add custom event handlers for non-native form-controls:
        //this.userType.addEventListener( 'MDCSelect:change', this.handleUserTypeSelectChange );
        //console.log( 'Called componentDidMount() from FocusLogin' );
    }
    componentWillUnmount() {
        // Remove custom event handlers for non-native form-controls:
        //this.userType.removeEventListener( 'MDCSelect:change', this.handleUserTypeSelectChange );
    }
    /**
     * Makes use of a validation helper for implementing form validation
     *
     * @param { string } field The field validation is run for
     * @param { string } value A field's input, converted to a string
     *
     * @returns { InputValidationType }
     *
     * @since 0.1.0
     */
    getValidation(field, value) {
        let options = {
            required: false,
            constrain: false,
            exclude: false,
            regex: /[~`!@#$%\^&*()_+=\-\[\]\\';,/{}|\\":<>\?]/g,
            errorMessage: ""
        }, validator = new helpers_1.FormValidation(), formField;
        switch (field) {
            case 'username':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.username.required;
                    options.constrain = true;
                    //options.exclude = true;
                    //options.regex = /[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g;
                    options.exclude = false;
                    // Explains how the ^ and $ and parenthesis means that the sequence must start
                    // and end with only alphanumeric (/w) at least at a length of 3. Nothing else
                    // may exist... so we have met our req's https://stackoverflow.com/a/4745147
                    // The comma in the quantifier spec ({3,}) tells us 3 or more. {3} would be
                    // exactly 3 occurences only.
                    options.regex = /^([\w]{3,})$/g;
                    options.errorMessage = "Username must be at least 3 characters, and may only contain alphanumeric and the following special characters: ['_']";
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'email':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.email.required;
                    options.constrain = true;
                    //options.exclude = true;
                    //options.regex = /[~`!#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g;
                    options.exclude = false;
                    options.regex = /^([\w.]+)([@]{1})([\w.]+)([.]{1})([\w]{2,})$/g;
                    options.errorMessage = "Email may only contain alphanumeric and the following special characters: ['_','.','@']. Must be in the form 'x@x.x";
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'confirmEmail':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.confirmEmail.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'password':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.password.required;
                    options.constrain = true;
                    //options.exclude = true;
                    //options.regex = /[`%*()=\-\[\]\\';,/{}|\\":<>\?]/g;
                    options.exclude = false;
                    // This regex says:
                    //
                    // (?=.*[\d]) = (Look Ahead Requirement): Password must contain at least 1 digit (0-9)
                    // (?=.*[a-z]) = (Look Ahead Requirement): Password must contain at least 1 lowercase alpha character
                    // (?=.*[A-Z]) = (Look Ahead Requirement): Password must contain at least 1 uppercase alpha character
                    // (?=.*[~!@#$%\^&+\?]) = (Look Ahead Requirement): Password must contain at least 1 of the specified special characters
                    // ([\w~!@#$%\^&+\?]{8,}) = The password must contain at least 8 of the specified chars (\w = a-zA-Z0-9_)
                    //
                    // The starting ^ and trailing $ says that nothing else can be present before or after the match.
                    //
                    // It's important to note that the last group is the only non-look-ahead, and specifies what characters will create a
                    // match (it must contain all the characters that are allowable). It's also important to note that the look aheads
                    // must separately provide requirements (one look ahead of [\w~!@#$%\^&+\?] will not work, as it will make only a
                    // requirement of there needing to be one of what is specified, rather than for a requirement of 1 of each type. Hence
                    // we must make a look ahead group for each different required character type.)
                    options.regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%\^&+\?])([\w~!@#$%\^&+\?]{8,})$/;
                    options.errorMessage = `Password must be at least 8 characters, and must consist of at least one lower-case letter, one upper-case letter, one number, and one of the following allowed special characters: ['~', '!', '@', '#', '$', '%', '^', '&', '_', '+', '?']`;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'confirmPassword':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.confirmPassword.required;
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'firstName':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.firstName.required;
                    options.constrain = true;
                    options.exclude = true;
                    options.errorMessage = "First name may only contain alpha characters.";
                    formField = validator.stageValidation(value, options);
                }
                break;
            case 'lastName':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.lastName.required;
                    options.constrain = true;
                    options.exclude = true;
                    options.errorMessage = "Last name may only contain alpha characters.";
                    formField = validator.stageValidation(value, options);
                }
                break;
        }
        return formField;
    }
    /**
     * Handle change event for the username text input
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleFormFieldInput(event) {
        //console.log( `InputChangeEvent: ${event.target.id}: ${event.target.value}` );
        // Get the validation state for this input:
        let inputValidation = this.getValidation(event.target.id, event.target.value), { validations, formValid } = this.state;
        if (event.target.id === 'confirmEmail' || event.target.id === 'confirmPassword') {
            let flagInvalid = false;
            if (event.target.id === 'confirmEmail') {
                // The formField is present whilst being required, let's check that its value
                // is equal to that of which it exists to confirm:
                if (event.target.value !== this.state.email) {
                    inputValidation.validation.message = "Email addresses do not match!";
                    flagInvalid = true;
                }
            }
            if (event.target.id === 'confirmPassword') {
                if (event.target.value !== this.state.password) {
                    inputValidation.validation.message = "Passwords do not match!";
                    flagInvalid = true;
                }
            }
            if (flagInvalid) {
                inputValidation.validation.class = "invalid-feedback";
                inputValidation.valid = false;
            }
        }
        validations = Object.assign(Object.assign({}, validations), { [event.target.id]: inputValidation });
        formValid = ( /*validations.username.valid &&*/validations.email.valid /*&& validations.confirmEmail.valid*/ &&
            validations.password.valid && validations.confirmPassword.valid /*&& validations.firstName.valid &&
        validations.lastName.valid*/);
        // Set the input value by the input provided:
        this.setState({ [event.target.id]: event.target.value });
        this.setState({ validations, formValid });
    }
    /**
     * Handle change event for the usertype select list
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleUserTypeSelectChange(event) {
        //console.log( `SelectChangeEvent: Selected option at index '${event.detail.index}' with value '${event.detail.value}'` );
        //( this._userType as any ).value = event.detail.value;
        // Set the select list by the input provided:
        //this.setState( { userType: event.detail.value } );
    }
    /**
     * Handle submit event for the form submit button
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleSubmit(event) {
        event.preventDefault();
        //console.log( `Submit pressed! Attempting to dispatch API request...` );
        /*
        let formData = new FormData();
        formData.append( 'userType', '3' );
        formData.append( 'username', 'devrikx' );
        formData.append( 'email', 'devrikx@gmail.com' );
        formData.append( 'password', '^Rkitek720$' );
        formData.append( 'firstName', 'Richard' );
        formData.append( 'lastName', 'Winters' );
        */
        const { /*userType,*/ username, email, password, /*firstName, lastName,*/ formValid } = this.state;
        // Here we dispatch the registration action - errors will be handled through
        // the dispatch actions error handler, and if no errors occur then we will
        // forward the user to the verification component?
        if (formValid) {
            let registrationResult = this.props.registerUser({ /*userType: userType,*/ username: username, email: email, password: password, }, this.props.history);
        }
        else {
            let ts = new Date();
            this.props.submitAdvisory({
                id: `FORM_ERROR`,
                title: `FORM ERROR ${ts.getTime()}`,
                content: `Please review the form and make corrections to form field inputs where necessary.`,
                isError: true
            });
        }
        //( this.props as any ).history.push( '/login' )
    }
    /**
     * Returns the brand image component
     *
     * @param void
     *
     * @return { any }
     *
     * @since 0.1.0
     */
    getImageBrand() {
        return (React.createElement(ECIBrand_1.ECIBrand, null));
    }
    /**
     * Returns the username text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getUserTypeInput() {
        let userTypeSelect = [];
        // Placeholder (no value puts label as placeholder) (view_quilt as icon)
        userTypeSelect.push(React.createElement(KMDSelectOption_1.KMDSelectOption, { key: 0, value: "", label: "", selected: true }));
        // Consumer option
        userTypeSelect.push(React.createElement(KMDSelectOption_1.KMDSelectOption, { key: 1, value: "consumer", label: "Consumer", selected: false }));
        // Provider option
        userTypeSelect.push(React.createElement(KMDSelectOption_1.KMDSelectOption, { key: 2, value: "provider", label: "Provider", selected: false }));
        // Build the enhanced select. We need to pass it a couple of
        // callbacks to facilitate the custom form-element, as we
        // otherwise cannot handle the changing of the select list's
        // values with regards to tracking the state of this component.
        //
        // Be sure to immediately invoke the methods, to get the returned callbacks:
        return (React.createElement(KMDSelect_1.KMDSelect, { label: "User Type", name: "userType", list: userTypeSelect, outlined: true, selectRefCallBack: this.userTypeSelectRefCallBack(), hiddenRefCallBack: this.userTypeSelectRefCallBack(true) }));
    }
    /**
     * Returns a ref callback, required for providing the capability to handle custom
     * event handlers for 'emulated' form fields. There should be a matching property
     * defined above the constructor for both the 'emulated' control, and its hidden
     * input (although the hidden input isn't utilized for now :P
     * )
     * @param { boolean } hidden Flags whether we want the ref callback for the 'emulated' control, or for the hidden control
     *
     * @returns { Function }
     *
     * @since 0.1.0
     */
    userTypeSelectRefCallBack(hidden = false) {
        if (!hidden) {
            //return ( ( element: any ) => this.userType = element );
            return () => { };
        }
        else {
            // For now, we'll just manipulate the hidden input's value to match
            // that of the 'emulated' select:
            //return ( ( element: any ) => this._userType = element );
            return () => { };
        }
    }
    /**
     * Returns the username text input
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    getUsernameInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "username", name: "username", label: "Username", input: "", extras: { required: true }, leadingIcon: true, icon: "account_box", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the email text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getEmailInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "email", name: "email", label: "Email", input: "", extras: { required: true }, leadingIcon: true, icon: "contact_mail", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the confirm email text input
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    getConfirmEmailInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "confirmEmail", name: "confirmEmail", label: "Confirm Email", input: "", extras: { required: true }, leadingIcon: true, icon: "contact_mail", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the first name text input
     *
     * @param void
     *
     * @return { any }
     *
     * @since 0.1.0
     */
    getFirstNameInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "firstName", name: "firstName", label: "First Name", input: "", extras: { required: true }, leadingIcon: true, icon: "contacts", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the last name text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getLastNameInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "lastName", name: "lastName", label: "Last Name", input: "", extras: { required: true }, leadingIcon: true, icon: "contacts", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the password text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getPasswordInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "password", name: "password", label: "Password", input: "", extras: { required: true }, password: true, leadingIcon: true, icon: "vpn_key", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Returns the confirm password text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getConfirmPasswordInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "confirmPassword", name: "confirmPassword", label: "Confirm Password", input: "", extras: { required: true }, password: true, leadingIcon: true, icon: "vpn_key", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        // Move state properties to a variable for cleaner manipulation:
        const { validations, formValid } = this.state;
        return (React.createElement(UserRegistration_1.UserRegistration
        //brand={this.getImageBrand()}
        //username={this.getUsernameInput()}
        //usernameValidation={validations.username}
        //userType={this.getUserTypeInput()}
        , { 
            //brand={this.getImageBrand()}
            //username={this.getUsernameInput()}
            //usernameValidation={validations.username}
            //userType={this.getUserTypeInput()}
            email: this.getEmailInput(), emailValidation: validations.email, 
            //confirmEmail={this.getConfirmEmailInput()}
            //confirmEmailValidation={validations.confirmEmail}
            password: this.getPasswordInput(), passwordValidation: validations.password, confirmPassword: this.getConfirmPasswordInput(), confirmPasswordValidation: validations.confirmPassword, 
            //first={this.getFirstNameInput()}
            //firstNameValidation={validations.firstName}
            //last={this.getLastNameInput()}
            //lastNameValidation={validations.lastName}
            handleSubmit: this.handleSubmit }));
    }
}
exports.FocusUserRegistration = FocusUserRegistration;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
FocusUserRegistration.defaultProps = {};
// Here we wrap the FocusUserRegistration Container with a Connect container,
// creating the FocusUserRegistrationContainer:
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(react_router_dom_1.withRouter(FocusUserRegistration));

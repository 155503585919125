"use strict";
/*-----------------------------------------------------------------------------
 * @package:    AppFooter
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
// EXPORTS
var AppFooter_1 = require("./AppFooter");
exports.default = AppFooter_1.default;
exports.AppFooter = AppFooter_1.AppFooter;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    FocusUserVerification
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const queryString = require("query-string");
const actions_1 = require("../../redux/actions");
const ECIBrand_1 = require("../../components/ECIBrand");
const UserVerification_1 = require("../../components/UserVerification");
const KMDTextField_1 = require("../../components/MaterialDesign/KMDTextField");
const helpers_1 = require("../../helpers");
const mapStateToProps = (state) => ({
    user: state.user,
    advisories: state.advisories
});
const mapDispatchToProps = (dispatch) => ({
    submitAdvisory: (advisory) => dispatch(actions_1.submitAdvisory(advisory)),
    signInUser: (credentials, history, sessionContext) => dispatch(actions_1.signInUser(credentials, history, sessionContext)),
    signOutUser: (history) => dispatch(actions_1.signOutUser(history)),
    verifyUser: (verificationInfo, history) => dispatch(actions_1.verifyUser(verificationInfo, history))
});
/**
 * FocusUserVerificationcontainer component
 *
 * @since 0.1.0
 */
class FocusUserVerification extends React.Component {
    /**
     * Constructor
     *
     * @param { FocusUserVerificationProps|any } props
     *
     * @since 0.1.0
     */
    constructor(props /*FocusUserVerificationProps*/) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'FocusUserVerification';
        let params = queryString.parse(this.props.location.search);
        this.state = {
            email: params.email || "",
            verificationCode: params.token || "",
            formValid: false,
            validations: {
                verificationCode: {
                    valid: false,
                    exists: false,
                    required: true,
                    label: "",
                    input: "",
                    validation: {
                        class: "",
                        message: ""
                    }
                }
            }
        };
        this.handleFormFieldInput = this.handleFormFieldInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Method which runs once a omponent has mounted
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        // Call kux.controls() to instantiate all the UX Goodies
        kux.controls();
        //console.log( 'Called componentDidMount() from FocusVerification.' );
        //console.log( `E-mail: ${this.state.email} Token: ${this.state.verificationCode}` );
    }
    /**
     * Makes use of a validation helper for implementing form validation
     *
     * @param { string } field The field validation is run for
     * @param { string } value A field's input, converted to a string
     *
     * @returns { InputValidationType }
     *
     * @since 0.1.0
     */
    getValidation(field, value) {
        let options = {
            required: false,
            constrain: false,
            exclude: false,
            regex: /[~`!@#$%\^&*()_+=\-\[\]\\';,/{}|\\":<>\?]/g,
            errorMessage: ""
        }, validator = new helpers_1.FormValidation(), formField;
        switch (field) {
            case 'verificationCode':
                {
                    // We have some helper functions to simplify required code
                    // when implementaing form field validation:
                    options.required = this.state.validations.verificationCode.required;
                    options.constrain = true;
                    options.exclude = false;
                    // Explains how the ^ and $ and parenthesis means that the sequence must start
                    // and end with only alphanumeric (/w) at least at a length of 3. Nothing else
                    // may exist... so we have met our req's https://stackoverflow.com/a/4745147
                    // The comma in the quantifier spec ({64,}) tells us 3 or more. {64} would be
                    // exactly 3 occurences only.
                    //
                    // 754ac911f2a655f77cfbf78a83bd52f8bbf92babd36b49e7bc189307d1af8fb8
                    options.regex = /^([\w]{64})$/g;
                    options.errorMessage = "The verification code is a 64 character token which only contains alphanumeric characters.";
                    formField = validator.stageValidation(value, options);
                }
                break;
        }
        return formField;
    }
    /**
     * Handle change event for the username text input
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleFormFieldInput(event) {
        //console.log( `InputChangeEvent: ${event.target.id}: ${event.target.value}` );
        // Get the validation state for this input:
        let inputValidation = this.getValidation(event.target.id, event.target.value), { validations, formValid } = this.state;
        validations = Object.assign(Object.assign({}, validations), { [event.target.id]: inputValidation });
        formValid = (validations.verificationCode.valid);
        // Set the input value by the input provided:
        this.setState({ [event.target.id]: event.target.value });
        this.setState({ validations, formValid });
    }
    /**
     * Handle submit event for the form submit button
     *
     * @param { React.FormEvent } event
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    handleSubmit(event) {
        event.preventDefault();
        //console.log( `Submit pressed! Attempting to dispatch API request...` );
        let formData = new FormData();
        formData.append('verificationCode', '754ac911f2a655f77cfbf78a83bd52f8bbf92babd36b49e7bc189307d1af8fb8');
        const { email, verificationCode, formValid } = this.state;
        // Here we dispatch the verification action - errors will be handled through
        // the dispatch action's error handler, and if no errors occur then we will
        // forward the user to the log-in component?
        if (formValid) {
            let verificationResult = this.props.verifyUser({ email: email, token: verificationCode }, this.props.history);
        }
        else {
            let ts = new Date();
            this.props.submitAdvisory({
                id: `FORM_ERROR`,
                title: `FORM ERROR ${ts.getTime()}`,
                content: `Please review the form and make corrections to form field inputs where necessary.`,
                isError: true
            });
        }
    }
    /**
     * Returns the brand image component
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    getImageBrand() {
        return (React.createElement(ECIBrand_1.ECIBrand, null));
    }
    /**
     * Returns the username text input
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    getVerificationCodeInput() {
        return (React.createElement(KMDTextField_1.KMDTextField, { id: "verificationCode", name: "verificationCode", label: "Verification Code", input: this.state.verificationCode || "", extras: { required: true }, leadingIcon: true, icon: "account_box", helperText: true, helpText: "", handleInputChange: this.handleFormFieldInput, outlined: true }));
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param void
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        const { email, verificationCode, validations } = this.state;
        return (React.createElement(UserVerification_1.UserVerification, { brand: this.getImageBrand(), email: email || "", verification: this.getVerificationCodeInput(), verificationValidation: validations.verificationCode, handleSubmit: this.handleSubmit }));
    }
}
exports.FocusUserVerification = FocusUserVerification;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
FocusUserVerification.defaultProps = {};
// Here we wrap the FocusUserVerification Container with a Connect container,
// creating the FocusUserVerificationContainer:
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(react_router_dom_1.withRouter(FocusUserVerification));

"use strict";
/*------------------------------------------------------------------------------
 * @package:   NodeKitCore
 * @author:    Richard B Winters
 * @copyright: 2018 Massively Modified, Inc..
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const UnderConstruction_1 = require("../UnderConstruction");
const ErrorHandler_1 = require("../ErrorHandler");
/* Import the default export of our AppBar so that the connect container
   is included! */
const AppBar_1 = require("../../containers/AppBar");
/* Import the default export of our AppAdvisories so that the connect container
   is included! */
const AppAdvisories_1 = require("../../containers/AppAdvisories/AppAdvisories");
/* Import the default export of our Focus components so that the connect
   container is included! */
const AppFocus_1 = require("../../containers/AppFocus");
/* Import the default export of our Footer so that the connect container
   is included! */
const AppFooter_1 = require("../../containers/AppFooter");
/**
 * KwaeriCore component
 *
 * @param { NodeKitCoreProps } props
 *
 * @since 0.1.0
 */
exports.NodeKitCore = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.nodekitAppContainerInner },
        React.createElement(AppBar_1.default, Object.assign({}, props)),
        React.createElement("div", { className: _1.styles.nodekitAppContainerStage },
            React.createElement("div", { className: "row " + _1.styles.contentBlock },
                React.createElement("div", { className: "column+12 column+8 column+4 " + _1.styles.contentBlockInner },
                    React.createElement(AppAdvisories_1.default, null),
                    React.createElement(react_router_dom_1.Switch, null,
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, { title: props.title }) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/privacy", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/terms", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/register", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/login", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/user/verify", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/user/account", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/user/settings", component: AppFocus_1.default }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/content/view/:identifier", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/content/add/:identifier", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/content/edit/:identifier", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/products", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/services", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/user/blog", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/user/blog/:userId", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { exact: true, path: "/about", render: (props) => React.createElement(UnderConstruction_1.UnderConstruction, Object.assign({}, props)) }),
                        React.createElement(react_router_dom_1.Route, { render: (props) => React.createElement(ErrorHandler_1.ErrorHandler, Object.assign({}, props)) }))))),
        React.createElement(AppFooter_1.AppFooter, null)));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.NodeKitCore.displayName = "nodekit Core";
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted to.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    Login
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * Login presentational component
 *
 * @param { LoginProps } props
 *
 * @since 0.1.0
 */
exports.Login = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: _1.styles.loginContainer },
        React.createElement("div", { className: "mdc-card" },
            React.createElement("div", { className: "feature-title" },
                React.createElement("i", { className: "feature-title_text-icon material-icons" }, "person"),
                " ",
                React.createElement("span", { className: "feature-title_text" }, "Log in:")),
            React.createElement("div", { className: "form-wrapper" },
                React.createElement("form", { onSubmit: props.handleSubmit },
                    React.createElement("div", { className: _1.styles.loginInputs },
                        React.createElement("div", { className: _1.styles.emailInput + " email-input" }, props.email),
                        React.createElement("div", { className: _1.styles.passwordInput + " password-input" }, props.password),
                        React.createElement("div", { className: _1.styles.rememberMeInput + " remember-me-input" }, props.rememberMe),
                        React.createElement("div", { className: _1.styles.loginButton },
                            React.createElement("button", { type: "submit", className: _1.styles.loginButtonText + " mdc-button mdc-button--raised" }, "Login")))),
                React.createElement("div", { className: "navlink-container navlink-container--full-bleed" },
                    React.createElement(react_router_dom_1.NavLink, { key: "forgot_password", to: "/forgot-password", className: "navlink" },
                        React.createElement("i", { className: "navlink_text-icon material-icons" }, "vpn_key"),
                        " ",
                        React.createElement("span", { className: "navlink_text" }, " Forgot password?")),
                    React.createElement(react_router_dom_1.NavLink, { key: "register", to: "/register", className: "navlink" },
                        React.createElement("i", { className: "navlink_text-icon material-icons" }, "person_add"),
                        " ",
                        React.createElement("span", { className: "navlink_text" }, " Create an account.")))))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.Login.displayName = 'Login';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

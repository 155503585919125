var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1QDlLk6VpcY25I1XxEe2o_ {\n  position: relative;\n  margin: 0;\n  margin-bottom: 25px;\n  padding: 0;\n  display: block;\n  width: auto;\n  height: auto; }\n  ._1QDlLk6VpcY25I1XxEe2o_ ._2dv81hBC0MGriLMr10dFOl {\n    margin: 0 auto;\n    background: transparent url(" + escape(require("../../assets/images/eci-logo.png")) + ") no-repeat;\n    background-size: contain;\n    width: 250px;\n    height: 76px;\n    border: none; }\n", ""]);

// exports
exports.locals = {
	"eci-brand_container": "_1QDlLk6VpcY25I1XxEe2o_",
	"eciBrandContainer": "_1QDlLk6VpcY25I1XxEe2o_",
	"eci-brand_image": "_2dv81hBC0MGriLMr10dFOl",
	"eciBrandImage": "_2dv81hBC0MGriLMr10dFOl"
};
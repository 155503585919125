"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDSelectOption
 * @author:     Richard B Winters
 * @copyright:  2019 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
/**
 * KMDSelectOption presentational component
 *
 * @param { KMDSelectOptionProps } props
 *
 * @since 0.1.0
 */
exports.KMDSelectOption = (props) => {
    // Here we render the component:
    return (React.createElement("li", Object.assign({ className: "mdc-list-item" + ((props.selected) ? " mdc-list-item--selected" : ""), "data-value": props.value }, ((props.selected) ? { "aria-selected": props.selected } : ''), { role: "option" }), props.label));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDSelectOption.displayName = 'KMDEnhancedSelectOption';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

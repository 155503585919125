"use strict";
/*-----------------------------------------------------------------------------
 * @package:    KMDTextField
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
/**
 * KMDTextField presentational component
 *
 * @param { KMDTextFieldProps } props
 *
 * @since 0.1.0
 */
exports.KMDTextField = (props) => {
    // Here we render the component:
    return (React.createElement("div", { className: "text-field-container" },
        React.createElement("div", { className: "mdc-text-field" + ((props.outlined) ? " mdc-text-field--outlined" : "") + ((props.leadingIcon) ? " mdc-text-field--with-leading-icon" : (props.trailingIcon) ? "mdc-text-field--with-trailing-icon" : ""), "data-field": "text" },
            (props.leadingIcon) ? React.createElement("i", { className: "material-icons mdc-text-field__icon" }, props.icon) : "",
            React.createElement("input", Object.assign({ type: (props.password) ? "password" : "text", id: props.id, className: "mdc-text-field__input", onChange: props.handleInputChange }, ((props.name && props.name !== "") ? { name: props.name } : {}), ((props.input && props.input !== "") ? { value: props.input } : {}), props.extras)),
            ((props.outlined) ?
                React.createElement("div", { className: "mdc-notched-outline" },
                    React.createElement("div", { className: "mdc-notched-outline__leading" }),
                    React.createElement("div", { className: "mdc-notched-outline__notch" },
                        React.createElement("label", { htmlFor: props.id, className: "mdc-floating-label" }, props.label)),
                    React.createElement("div", { className: "mdc-notched-outline__trailing" })) :
                React.createElement("label", { htmlFor: props.id, className: "mdc-floating-label" }, props.label)),
            ((props.outlined) ?
                "" :
                React.createElement("div", { className: "mdc-line-ripple" })),
            (props.trailingIcon) ? React.createElement("i", { className: "material-icons mdc-text-field__icon" }, props.icon) : ""),
        React.createElement("div", { className: "mdc-text-field-helper-line" }, (props.helperText) ? React.createElement("p", { className: "mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg", id: "validation-msg" }, props.helpText) : "")));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.KMDTextField.displayName = 'KMDTextField';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    mmod-co/core-react
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// DEFINES
/**
 * Form validation class
 *
 * @since 0.1.0
 */
class FormValidation {
    /**
     * Class constructor
     *
     * @since 0.1.0
     */
    constructor() {
    }
    /**
     * Returns a InputValidationType object for assisting with form field validation
     * within React components
     *
     * @param { string } value User input converted to a string
     * @param { any } options Optional params that configure the validation process for a value
     *
     * @return { InputValidationType } An InputValidationType object, staged for aspecific input field and its user provided value
     *
     * @since 0.1.0
     */
    stageValidation(value, options = {}) {
        // Ensure that input exists:
        let parameters = {
            required: (options.required) ? true : false,
            constrain: (options.constrain) ? true : false,
            regex: (options.regex) ? options.regex : /[~`!@#$%^&*+=-[]\';,\/{}|\":<>?]/g,
            errorMessage: (options.errorMessage) ? options.errorMessage : ""
        }, exists = (value.length >= 1) ? true : false, formField = {
            valid: false,
            exists: exists,
            required: parameters.required,
            label: "",
            input: "",
            validation: {
                class: "valid-feedback",
                message: ""
            }
        };
        // If input is present perform some additional validation prep:
        if (formField.exists) {
            /* We should cover any length requirements:
            if( options && ( options.minLength || options.maxLength ) )
            {
                if( options.minLength )
                {
                    formField.valid = ( formField.exists && value.length >= options.minLength ) ? true : false;

                    if( !formField.valid )
                    {
                        parameters.errorMessage = ""
                    }
                }

                if( options.maxLength )
                {
                    formField.valid = ( formField.exists && value.length <= options.maxLength ) ? true : false;
                }

                if( options.minLength && options.maxLength )
                {
                    formField.valid = ( formField.exists && ( value.length >= options.minLength && value.length <= options.maxLength ) ) ? true : false;
                }
            }
            */
            // If exclude is true, we want to flag false if something is found:
            if (options && options.exclude) {
                formField.valid = (parameters.constrain) ? !parameters.regex.test(value) : formField.exists;
            }
            else {
                // Otherwise, it's potentially a template requirement (like email address formatting):
                formField.valid = (parameters.constrain) ? parameters.regex.test(value) : formField.exists;
            }
            // Some more staging of the validation props based upon the result:
            if (formField.valid) {
                formField.label = "text-success";
                formField.input = "is-valid";
            }
            else {
                formField.label = "text-danger";
                formField.input = "is-invalid";
                formField.validation.class = "invalid-feedback";
                formField.validation.message = parameters.errorMessage;
            }
        }
        else {
            if (formField.required) {
                formField.label = "text-danger";
                formField.input = "is-invalid";
                formField.validation.class = "invalid-feedback";
                formField.validation.message = "This field is required";
            }
            else {
                formField.valid = true;
            }
        }
        return formField;
    }
    /**
     * Returns a reset from validation state object
     *
     * @param { boolean } required The required flag for the validation object being reset
     *
     * @return { InputValidationType } Returns an InputValidationType object which represents a reset form validation state object
     *
     * @since 0.1.0
     */
    reset(required) {
        return ({
            valid: false,
            exists: false,
            required: required,
            label: "",
            input: "",
            validation: {
                class: "",
                message: ""
            }
        });
    }
}
exports.FormValidation = FormValidation;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    AppFocus
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const React = require("react");
const react_redux_1 = require("react-redux");
const actions_1 = require("../../redux/actions");
const FocusMain_1 = require("../FocusMain");
const FocusPrivacy_1 = require("../FocusPrivacy");
const FocusTerms_1 = require("../FocusTerms");
const FocusLogin_1 = require("../FocusLogin");
const FocusUserRegistration_1 = require("../FocusUserRegistration/FocusUserRegistration");
const FocusUserVerification_1 = require("../FocusUserVerification");
const FocusUserAccount_1 = require("../FocusUserAccount");
const FocusUserSettings_1 = require("../FocusUserSettings");
const FocusContent_1 = require("../FocusContent");
const FocusContentForm_1 = require("../FocusContentForm");
const mapStateToProps = (state) => ({
    user: state.users.user
});
const mapDispatchToProps = (dispatch) => ({
    signInUser: (credentials, history, sessionContext) => dispatch(actions_1.signInUser(credentials, history, sessionContext)),
    signOutUser: (history) => dispatch(actions_1.signOutUser(history))
});
/**
 * AppFocuscontainer component
 *
 * @since 0.1.0
 */
class AppFocus extends React.Component {
    /**
     * Constructor
     *
     * @param { AppFocusProps } props
     *
     * @since 0.1.0
     */
    constructor(props) {
        super(props);
        /**
         * @var { string } displayName Always set the display name
         *
         * @since 0.1.0
         */
        this.displayName = 'AppFocus';
        this.state = {
            user: {}
        };
    }
    /**
     * Method which runs once a omponent has mounted
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidMount() {
        // Call kux.controls() to instantiate all the UX Goodies
        // kux.controls();
        //console.log( 'Called componentDidMount()' );
    }
    /**
     * Method which runs once a omponent has updated
     *
     * @param void
     *
     * @returns { void }
     *
     * @since 0.1.0
     */
    componentDidUpdate() {
        //console.log( 'Called componentDidUpdate()' );
    }
    /**
     * Method to sort which focus should be loaded for the AppFocus
     *
     * @param { void }
     *
     * @return { any }
     *
     * @since 1.0.0
     */
    getFocus() {
        // This method will sort the focus which should be loaded:
        if (this.props && this.props.location.pathname) {
            console.log(`Current route: ${this.props.location.pathname}`);
            switch (this.props.location.pathname) {
                case '/login':
                    {
                        return (React.createElement(FocusLogin_1.default, null));
                    }
                    break;
                case '/privacy':
                    {
                        return (React.createElement(FocusPrivacy_1.default, null));
                    }
                    break;
                case '/terms':
                    {
                        return (React.createElement(FocusTerms_1.default, null));
                    }
                    break;
                case '/register':
                    {
                        return (React.createElement(FocusUserRegistration_1.default, null));
                    }
                    break;
                case '/user/verify':
                    {
                        return (React.createElement(FocusUserVerification_1.default, null));
                    }
                    break;
                case '/user/acount':
                    {
                        return (React.createElement(FocusUserAccount_1.default, null));
                    }
                    break;
                case '/user/settings':
                    {
                        return (React.createElement(FocusUserSettings_1.default, null));
                    }
                    break;
                case '/content/view':
                    {
                        return (React.createElement(FocusContent_1.default, null));
                    }
                    break;
                case '/content/Add':
                    {
                        return (React.createElement(FocusContentForm_1.default, null));
                    }
                    break;
                case '/content/edit':
                    {
                        return (React.createElement(FocusContentForm_1.default, null));
                    }
                    break;
                default:
                    {
                        return (React.createElement(FocusMain_1.default, null));
                    }
                    break;
            }
        }
        // The default path should always load the search launch
        return (React.createElement(FocusMain_1.default, null));
    }
    /**
     * Container components pass rendering responsibilities off to
     * presentational components
     *
     * @param { void }
     *
     * @returns { any }
     *
     * @since 0.1.0
     */
    render() {
        return (this.getFocus());
    }
}
exports.AppFocus = AppFocus;
/**
* We'd set propTypes here if this wasn't Typescript.
*/
/**
 * @var { any } defaultProps The component's default property values
 *
 * @since 0.1.0
 */
AppFocus.defaultProps = {};
// Here we wrap the AppFocus Container with a Connect container,
// creating the AppFocusContainer:
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(AppFocus);

"use strict";
/*------------------------------------------------------------------------------
 * @package:   mmod-co/core-react
 * @author:    Richard B Winters
 * @copyright: 2018 Richard B Winters.
 * @license:   Apache v2.0
 * @version:   0.2.0
 *----------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const action_types_1 = require("../constants/action-types");
// DEFINES
const initialState = {
    advisories: []
};
const advisories = (state = initialState, action) => {
    //console.log( `ACTION_TYPE: ${action.type}` );
    switch (action.type) {
        case action_types_1.SUBMIT_ADVISORY:
            {
                // Return a new state object that is a copy of the original state
                // plus the additions present in the payload
                //
                // If the ID of the payload matches an existing one, let's filter
                // the existing advisory out and replace it with the new one in
                // the process:
                let id = action.payload.advisory.id, filteredAdvisories = state.advisories.filter((item) => { if (item.advisory.id !== id)
                    return item; });
                return Object.assign(Object.assign({}, state), { advisories: [...filteredAdvisories, action.payload] });
                //return { ...state, advisories: [...state.advisories, action.payload] };
            }
            break;
        case action_types_1.REMOVE_ADVISORY:
            {
                // Grab the id of the advisory we're removing, from the payload:
                let id = action.payload.advisory.id;
                // Use the filter function to build a new array populated by all the
                // original advisories other than the one whos id matches that of the
                // advisory that was dismissed - as noted by the payload. filter() will
                // not increment on non-matching items, so that there are no empty
                // indices in the resulting array..:(i.e. using .map() with the same
                // callback will result in an array the same size, but with an undefined
                // indice - requiring a call to .filter( Boolean ) to clean up.)
                let updatedAdvisories = state.advisories.filter((item) => { if (item.advisory.id !== id)
                    return item; });
                // Return a new state object that is a copy of the original state
                // plus the changes from our payload applied, holding to the redux
                // rule of immutable state:(a new advisories object supplied ofc)
                return Object.assign(Object.assign({}, state), { advisories: updatedAdvisories });
            }
            break;
        default:
            {
                return state;
            }
            break;
    }
};
// Export it:
exports.default = advisories;

"use strict";
/*-----------------------------------------------------------------------------
 * @package:    TopAppBar
 * @author:     Richard B Winters
 * @copyright:  2018 Massively Modified, Inc.
 * @license:    Apache-2.0 <http://www.apache.org/licenses/LICENSE-2.0>
 * @version:    0.2.0
 *---------------------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
// INCLUDES
const _1 = require("./");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * TopAppBar presentational component
 *
 * @param { TopAppBarProps } props
 *
 * @since 0.1.0
 */
exports.TopAppBar = (props) => {
    // Here we render the component:
    return (React.createElement("header", { className: "mdc-top-app-bar mdc-top-app-bar--fixed " + _1.styles.nodekitAppBar, "data-animation": "rebound-scroll", "data-rebounded": "false", "data-menu": "top-menu" },
        React.createElement("div", { className: "mdc-top-app-bar__row " + _1.styles.nodekitAppBarRow },
            React.createElement("section", { className: "mdc-top-app-bar__section mdc-top-app-bar__section--align-start" },
                React.createElement("a", { "data-menu": "navigation-drawer", "data-drawer": "permanent", className: "material-icons mdc-top-app-bar__navigation-icon hidden" }, "menu"),
                React.createElement(react_router_dom_1.NavLink, { key: "icon_badge_link", to: "/", className: _1.styles.appIconHomeLink },
                    React.createElement("div", { className: "navbar_brand icon-logo-badge-small" })),
                React.createElement(react_router_dom_1.NavLink, { key: "app_title_link", to: "/", className: _1.styles.appTitleHomeLink },
                    React.createElement("span", { className: "mdc-top-app-bar__title " + _1.styles.nodekitAppBarTitle }, props.title))),
            React.createElement("section", { className: "mdc-top-app-bar__section mdc-top-app-bar__section--align-end ", role: "toolbar" })),
        React.createElement("div", { className: "mdc-top-app-bar__row " + _1.styles.nodekitAppBarMenu },
            React.createElement("section", { className: "mdc-top-app-bar__section mdc-top-app-bar__section--align-start", role: "menu" },
                React.createElement(react_router_dom_1.NavLink, { key: "app_menu_products_link", to: '/products', className: _1.styles.appMenuLink }, "Products"),
                React.createElement(react_router_dom_1.NavLink, { key: "app_menu_services_link", to: '/services', className: _1.styles.appMenuLink }, "Services"),
                React.createElement(react_router_dom_1.NavLink, { key: "app_menu_blog_link", to: '/user/blog', className: _1.styles.appMenuLink }, "Blog"),
                React.createElement(react_router_dom_1.NavLink, { key: "app_menu_about_link", to: '/about', className: _1.styles.appMenuLink }, "About")),
            React.createElement("section", { className: "mdc-top-app-bar__section mdc-top-app-bar__section--align-end " }))));
};
/**
 * @var { string } displayName Always set the display name
 *
 * @since 0.1.0
 */
exports.TopAppBar.displayName = 'TopAppBar';
/**
 * We'd set the propTypes here if this wasn't Typescript.
 * We'd set the defaultProps here if we wanted them.
 */
